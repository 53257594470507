import { MintInfo, useContractValues } from 'context/ContractValuesProvider';
import { useWeb3Context } from 'context/Web3Provider';
import { Contract } from 'helpers/addresses';
import {
    CollateralInfo,
    getCollateralInfo,
} from 'helpers/collateral';
import { usdToEth } from 'helpers/conversions';
import {
    getPreferredBankxAction,
    PreferredBankxAction,
} from 'helpers/getPreferredBankxAction';
import {
    getBankxInterest,
    getRewardsCTA,
    getRoiCopy,
    getVestingTime,
    getXSDInterest,
} from 'helpers/liquidity';
import { Logger } from 'helpers/logging';
import { checkIfMintIsDisabled } from 'helpers/minting';
import type { TNFTInfo } from 'helpers/nft';
import { formatDecimalToPercentage } from 'helpers/number';
import { calculateArbitrageMax } from 'helpers/pid';
import { getCollateralAction, getSystemCollateralPercentage } from 'helpers/pool';
import { debounce } from 'lodash';
import {
    useCallback, useEffect, useMemo, useState
} from 'react';

import { useWallet } from './useWallet';

interface LiquidityPoolValues {
    deficit: string;
    maxContribution: string;
    maxContributionInEth: string;
    raised: string;
    vestingTime: string;
    bankxRoi: string;
    xsdRoi: string;
    totalRoi: number;
    rewardsCTACopy: string;
    roiCopy: string;
}
export interface Globals {
    bankxAction: PreferredBankxAction | null;
    bankxPoolBalance: string;
    bankxPoolLiquidity: LiquidityPoolValues;
    bankXTokenPrice: string;
    chain: string;
    collateralPercentage: string;
    collateralPoolBalance: string;
    collateralInfo: CollateralInfo;
    ethPriceInUsd: string;
    mintInfo: Partial<MintInfo>;
    mintInterestRate: string;
    pidCooldownDuration: number,
    priceDifference: number;
    silverPrice: string;
    xsdPoolBalance: string;
    xsdPoolLiquidity: LiquidityPoolValues;
    xsdPrice: string;
    xsdTotalSupply: string;
    xsdTotalSupplyInUsd: string;
    certificateOfDepositInfo: {
        LPB: string;
        LPBBonusPercent: string;
        currentDay: string;
    } | null
    tvlValue: string;
    bankxInflation: string;
    systemCollateralPercentage: number;
    buyBack: {
        // usd amount the collateral pool has in excess
        excessAmountInUsd: number;
        // max bankx the user can input
        maxBankxInput: number;
        // max xsd the user can input
        maxXsdInput: number;
        // max xsd the contract will allow
        xsdLimit: number;
    };
    nftInfo: TNFTInfo | null;
    maxExcessXsdAvailableForBuyBack: number;
    availableExcessCollatDV: string;
    areValuesLoading: boolean;
    setAreValuesLoading(isLoading: boolean): void;
    arbitrage: {
        max: string;
        isPaused: boolean;
    },
    collateralAction: string;
    isMintDisabled: boolean;
    topCards: {
        differential: {
            label: string;
            value: string;
            percentage: string;
        }
    };
    tokenLabel: string;
}

const defaultValues = {
    bankxAction: null,
    bankxPoolBalance: '',
    bankXTokenPrice: '',
    chain: '',
    collateralInfo: getCollateralInfo(0, 0, '0'),
    collateralPercentage: '',
    collateralPoolBalance: '',
    ethPriceInUsd: '',
    mintInfo: {},
    mintInterestRate: '',
    priceDifference: 0,
    silverPrice: '',
    xsdPoolBalance: '',
    xsdPrice: '',
    xsdTotalSupply: '-',
    xsdTotalSupplyInUsd: '-',
    bankxPoolLiquidity: {
        deficit: '-',
        maxContribution: '-',
        maxContributionInEth: '-',
        raised: '-',
        vestingTime: '-',
        bankxRoi: '-',
        xsdRoi: '-',
        totalRoi: 0,
        rewardsCTACopy: '',
        roiCopy: '',
    },
    xsdPoolLiquidity: {
        deficit: '-',
        maxContribution: '-',
        maxContributionInEth: '-',
        raised: '-',
        vestingTime: '-',
        bankxRoi: '-',
        xsdRoi: '-',
        totalRoi: 0,
        rewardsCTACopy: '',
        roiCopy: '',
    },
    pidCooldownDuration: 0,
    certificateOfDepositInfo: null,
    tvlValue: '-',
    bankxInflation: '-',
    systemCollateralPercentage: NaN,
    buyBack: {
        excessAmountInUsd: 0,
        maxBankxInput: 0,
        maxXsdInput: 0,
        xsdLimit: 0,
    },
    nftInfo: null,
    maxExcessXsdAvailableForBuyBack: 0,
    availableExcessCollatDV: '-',
    areValuesLoading: true,
    arbitrage: {
        max: '-',
        isPaused: true,
    },
    collateralAction: getCollateralAction(NaN, NaN),
    isMintDisabled: true,
    topCards: {
        differential: {
            percentage: '',
            label: '',
            value: '',
        }
    },
    tokenLabel: '-',
};

export function useGetGlobals(): Globals {
    const [state, setState] = useState<Omit<Globals, 'setAreValuesLoading'>>({ ...defaultValues });

    const contractValues = useContractValues();
    const {
        chain,
        isConnected,
        token,
        tokenLabel,
    } = useWallet();

    // debounce to properly handle loading chain that is not sepolia
    const refresh = useMemo(() => debounce((contractValues: ReturnType<typeof useContractValues>, token: string, tokenLabel: string): void => {
        (async() => {
            const [
                collateralPercentage,
                deficits,
                ethPriceInUsd,
                mintInfo,
                mintInterestRate,
                xsdTotalSupply,
                amountsPaid,
                pidCooldownDuration,
                bankxPoolBalance,
                xsdPoolBalance,
                collateralPoolBalance,
                bankxInflation,
                collateralBackedXSDInCirculation,
                availableExcessCollatDV,
                isArbitragePaused,
                arbitrageBurnLimits,
                tokenPrices,
            ] = await Promise.all([
                contractValues.getCollateralPercentage(),
                contractValues.getDeficits(),
                contractValues.getETHPrice(),
                contractValues.getMintInfo(),
                contractValues.getMintingInterestRate(),
                contractValues.getXSDTotalSupply(),
                contractValues.getAmountsPaid(),
                contractValues.getPIDRefreshCooldownDuration(),
                contractValues.getBankxCollateralBalance(),
                contractValues.getXSDCollateralBalance(),
                contractValues.getCollateralPoolBalance(),
                contractValues.getBankxInflation(),
                contractValues.getCollateralBackedXSDInCirculation(),
                contractValues.getAvailableExcessCollatDV(),
                contractValues.getIsArbitragePaused(),
                contractValues.getArbitrageBurnMax(),
                contractValues.getTokenPrices(),
            ]);

            // note: these contracts are not always updated together with the others
            // this means, in some cases, the app must work without these
            const nftCdResults = await Promise.allSettled([
                contractValues.getCertificateOfDepositInfo(),
                contractValues.getNFTInfo(),
                null, // null to prevent auto-format
            ]);

            // Promise.allSettled has a different response format from Promise.resolved
            const [certificateOfDepositInfo, nftInfo] = nftCdResults.map((x: any) => x?.value);

            const silverPrice = mintInfo.silverPrice;
            const { bankx: bankXTokenPrice, xsd: xsdPrice } = tokenPrices;

            const {
                bankx: bankxRaised,
                collateral: collateralRaised,
                xsd: xsdRaised,
            } = amountsPaid;
            const {
                bankx: bankxDeficit,
                collateral: collateralDeficit,
                xsd: xsdDeficit,
            } = deficits;
            const priceDifference = Number(xsdPrice) - Number(silverPrice);
            const bankxMax = Number(bankxDeficit) - Number(bankxRaised);
            const xsdMax = Number(xsdDeficit) - Number(xsdRaised);
            const bankxPoolValues = {
                maxContribution: String(Math.max(0, bankxMax)),
                bankxRoi: getBankxInterest(Contract.BankXPool),
                xsdRoi: getXSDInterest(bankxDeficit, bankxRaised),
            };
            const xsdPoolValues = {
                maxContribution: String(Math.max(0, xsdMax)),
                bankxRoi: getBankxInterest(Contract.XSDPool),
                xsdRoi: getXSDInterest(xsdDeficit, xsdRaised),
            };

            // (xsd supply - xsd minted) should be greater than 0
            //  totalSupply - availableExcessCollatDV - collat_XSD
            const maxXsdForBuyback = (Number(xsdTotalSupply) - Number(collateralBackedXSDInCirculation)) * Number(xsdPrice);
            const xsdUsdMax = Number(availableExcessCollatDV) / Number(xsdPrice);

            Logger.debug('useGetGlobals: maxXsdForBuyback', maxXsdForBuyback);
            Logger.debug('useGetGlobals: xsdUsdMax', xsdUsdMax);
            const maxExcessXsdAvailableForBuyBack = Math.max(
                Math.min(
                    // The maximum usd value available for buy back - converted to xsd
                    xsdUsdMax,

                    // The maximum amount of XSD the contract can receive
                    maxXsdForBuyback,
                ),
                0, // cannot be below 0
            );

            const systemCollateralPercentage = getSystemCollateralPercentage(collateralPoolBalance, collateralBackedXSDInCirculation, silverPrice);

            // this is the current deficit. collatera deficit only updates once a week
            const currentDeficit = Number(collateralDeficit) - Number(collateralRaised);

            const topCardDiffential = {
                label: '',
                value: '',
                percentage: '',
            };
            {
                // BAN-344: show percentage profit/discount in top card instead of differential
                const percentage = (priceDifference / Number(silverPrice)) * 100;
                const value = `${Math.abs(percentage).toFixed(2)}%`;
                topCardDiffential.percentage = value;

                const thePeg = 0;
                // below the peg
                if (percentage < thePeg) {
                    topCardDiffential.label = 'Discount:';
                    topCardDiffential.value = `${value} BUY`;
                }

                // above the peg
                if (percentage > thePeg) {
                    topCardDiffential.label = 'Profit:';
                    // TODO: replace with proper styling. whitespace for now
                    topCardDiffential.value = `${value}         SELL`;
                }
            }

            const bankxPoolLiquidity = {
                deficit: bankxDeficit,
                maxContribution: bankxPoolValues.maxContribution,
                maxContributionInEth: usdToEth(bankxPoolValues.maxContribution, ethPriceInUsd),
                raised: bankxRaised,
                vestingTime: getVestingTime(bankxDeficit, bankxRaised),
                bankxRoi: formatDecimalToPercentage(bankxPoolValues.bankxRoi),
                xsdRoi: formatDecimalToPercentage(bankxPoolValues.xsdRoi),
                totalRoi: bankxPoolValues.bankxRoi + bankxPoolValues.xsdRoi,
            };
            const xsdPoolLiquidity = {
                deficit: xsdDeficit,
                maxContribution: xsdPoolValues.maxContribution,
                maxContributionInEth: usdToEth(xsdPoolValues.maxContribution, ethPriceInUsd),
                raised: xsdRaised,
                vestingTime: getVestingTime(xsdDeficit, xsdRaised),
                bankxRoi: formatDecimalToPercentage(xsdPoolValues.bankxRoi),
                xsdRoi: formatDecimalToPercentage(xsdPoolValues.xsdRoi),
                totalRoi: xsdPoolValues.bankxRoi + xsdPoolValues.xsdRoi,
            };
            const collateralInfo = getCollateralInfo(Number(collateralDeficit), Number(collateralRaised), ethPriceInUsd);
            const globals = {
                bankxAction: getPreferredBankxAction(
                    xsdPoolLiquidity.maxContribution,
                    bankxPoolLiquidity.maxContribution,
                    collateralInfo.maxContribution,
                ),
                bankxPoolBalance,
                bankXTokenPrice,
                chain: chain as string,
                collateralPercentage,
                collateralPoolBalance,
                collateralInfo,
                ethPriceInUsd,
                mintInfo: mintInfo || {},
                mintInterestRate,
                xsdPrice,
                silverPrice: String(silverPrice),
                pidCooldownDuration,
                priceDifference,
                xsdPoolBalance,
                xsdTotalSupply,
                xsdTotalSupplyInUsd: String(Number(xsdTotalSupply) * Number(xsdPrice)),
                bankxPoolLiquidity: {
                    ...bankxPoolLiquidity,
                    rewardsCTACopy: getRewardsCTA({
                        bankxRoi: bankxPoolValues.bankxRoi,
                        xsdRoi: bankxPoolValues.xsdRoi,
                        vestingPeriod: bankxPoolLiquidity.vestingTime,
                        token: tokenLabel,
                    }),
                    roiCopy: getRoiCopy(bankxPoolValues.bankxRoi),
                },
                xsdPoolLiquidity: {
                    ...xsdPoolLiquidity,
                    rewardsCTACopy: getRewardsCTA({
                        bankxRoi: xsdPoolValues.bankxRoi,
                        xsdRoi: xsdPoolValues.xsdRoi,
                        vestingPeriod: xsdPoolLiquidity.vestingTime,
                        token: tokenLabel,
                    }),
                    roiCopy: getRoiCopy(xsdPoolValues.bankxRoi),
                },
                certificateOfDepositInfo,
                tvlValue: collateralPoolBalance,
                bankxInflation,
                systemCollateralPercentage,
                buyBack: {
                    excessAmountInUsd: Number(availableExcessCollatDV),
                    maxXsdInput: maxExcessXsdAvailableForBuyBack,
                    maxBankxInput: Math.max(0, Number(availableExcessCollatDV) / Number(bankXTokenPrice)),
                    xsdLimit: maxXsdForBuyback,
                },
                nftInfo,
                maxExcessXsdAvailableForBuyBack,
                availableExcessCollatDV,
                areValuesLoading: false,
                arbitrage: {
                    max: calculateArbitrageMax(
                        arbitrageBurnLimits.minArbBurnBelow,
                        arbitrageBurnLimits.maxArbBurnAbove,
                        priceDifference,
                        xsdPrice,
                        bankXTokenPrice,
                    ),
                    isPaused: isArbitragePaused,
                },
                collateralAction: getCollateralAction(currentDeficit, Number(availableExcessCollatDV)),
                isMintDisabled: checkIfMintIsDisabled(silverPrice, xsdPrice),
                topCards: {
                    differential: topCardDiffential,
                },
                tokenLabel,
            };

            Logger.log('globals', globals);

            setState(globals);
        })();

    }, 800, {
        leading: false,
        trailing: true
    }), [chain]);

    const { isReady } = useWeb3Context();
    const setAreValuesLoading = useCallback((isLoading: boolean): void => {
        setState((prev): Omit<Globals, 'setAreValuesLoading'> => {
            return {
                ...prev,
                areValuesLoading: isLoading,
            };
        });
    }, []);

    useEffect(() => {
        if (!isConnected) {
            setState({ ...defaultValues });
            setAreValuesLoading(false);
        }

        if (!isConnected || !isReady) {
            return;
        }

        setAreValuesLoading(true);
        refresh(contractValues, token, tokenLabel);
    }, [
        contractValues,
        isConnected,
        refresh,
        isReady,
        setAreValuesLoading,
        token,
        tokenLabel,
    ]);

    return useMemo((): Globals => {
        return {
            ...state,
            setAreValuesLoading,
        };
    }, [state, setAreValuesLoading]);
}
