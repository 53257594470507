import cx from 'classnames';
import { Carousel } from 'components/Carousel';
import { ConnectButton, ConnectButtonText } from 'components/ConnectButton/ConnectButton';
import { RewardsGroup } from 'components/RewardsGroup';
import {
    TextInput, TextInputName, useTextInputContext
} from 'components/TextInput';
import { useAppSettings } from 'context/AppSettingsProvider';
import { useGlobalValues } from 'context/ContractValuesProvider';
import { useWeb3Context } from 'context/Web3Provider';
import { Contract } from 'helpers/addresses';
import { addCollateral, getRewardValues } from 'helpers/collateral';
import { isValidInput } from 'helpers/input';
import { Logger } from 'helpers/logging';
import { collectRedemption } from 'helpers/redeem';
import { CustomLoadingMessage, useCustomLoadingMessage } from 'hooks/useCustomLoadingMessages';
import { useWallet } from 'hooks/useWallet';
import React, { useEffect, useState } from 'react';

import { FormattedValue } from '../../../components/FormattedValue/FormattedValue';
import { Settings } from '../../../components/Settings';
import Styles from '../styles.module.sass';

interface NegativeCollateralProps {
    disabled?: boolean;
}

export function NegativeCollateral(props: NegativeCollateralProps): React.ReactElement {
    const { getThemedClass: tcx } = useAppSettings();
    const globalValues = useGlobalValues();
    const walletValues = globalValues.walletValues;
    const { collateralInfo } = globalValues;

    const { useInputState } = useTextInputContext();
    const [inputAmount, setInputAmount] = useInputState(TextInputName.NegativeCollateral);

    const rewardsPreview = getRewardValues(
        inputAmount,
        globalValues.ethPriceInUsd,
        globalValues.bankXTokenPrice,
        globalValues.silverPrice,
        collateralInfo,
        Contract.CollateralPool,
    );

    const { items: rewards, summary: rewardSummary } = walletValues.rewards.collateral;

    function handleMaxBtnClick() {
        if (props.disabled) {
            return;
        }
        const max = Math.min(Number(walletValues.balance), Number(globalValues.collateralInfo.maxContributionInEth));
        setInputAmount(String(max));
    }

    function onInputChange(e: React.ChangeEvent<HTMLInputElement>): void {
        setInputAmount(e.currentTarget.value);
    }

    const { contracts, getProvider } = useWeb3Context();
    const {
        collateralPoolContract,
        routerContract,
        pidContract,
        proxyContract,
    } = contracts;
    const provider = getProvider();
    const {
        address: walletAddress,
        tokenLabel: token,
        priceCheckBlockDelay,
    } = useWallet();
    const {
        isLoading: isCollateralLoading,
        message: collateralCustomLoadingMessage,
        next: goToNextCollateralLoadingMessage,
        reset: resetCollateralLoadingMessages,
    } = useCustomLoadingMessage();

    async function onAddCollateral(): Promise<void> {
        try {
            resetCollateralLoadingMessages(3);
            await addCollateral({
                inputAmount,
                routerContract,
                provider,
                targetPoolAddress: collateralPoolContract.address,
                walletAddress,
                goToNextLoadingMessage: goToNextCollateralLoadingMessage,
                customLoadingMessage: CustomLoadingMessage.NegativeCollateral,
                pidContract,
                proxyContract,
                priceCheckBlockDelay,
                chain: globalValues.chain,
            });
        } catch(e) {
            Logger.error(e);
            throw e;
        }
    }

    const [selectedWithdrawal, setSelectedWithdrawal] = useState(rewards[0]);

    useEffect((): void => {
        setSelectedWithdrawal(rewards[0]);
    }, [rewards]);

    function onCarouselChange(item: typeof rewards[0]): void {
        setSelectedWithdrawal(item);
    }

    const {
        isLoading: isWithdrawLoading,
        message: withdrawalCustomLoadingMessage,
        next: goToNextWithdrawLoadingMessage,
        reset: resetWithdrawLoadingMessages,
    } = useCustomLoadingMessage();

    async function onWithdraw(): Promise<void> {
        try {
            resetWithdrawLoadingMessages(2);
            await collectRedemption(
                collateralPoolContract,
                pidContract,
                provider,
                goToNextWithdrawLoadingMessage,
                globalValues.chain,
                proxyContract,
                priceCheckBlockDelay,
            );
        } catch(e) {
            Logger.error(e);
            throw e;
        }
    }

    const hasValidInput = isValidInput(inputAmount);
    const limitMessage = function(): string {
        const isLimitExceeded = Number(inputAmount) * Number(globalValues.ethPriceInUsd) > Number(collateralInfo.maxContribution);
        const isBalanceExceeded = Number(inputAmount) > Number(walletValues.balance);

        if (hasValidInput && isBalanceExceeded) {
            return ConnectButtonText.NotEnoughBalance;
        }
        if (hasValidInput && isLimitExceeded) {
            return ConnectButtonText.MaxAmountExceeded;
        }
        return ConnectButtonText.Empty;
    }();

    const isAddCollateralDisabled = limitMessage.length > 0 || !hasValidInput || props.disabled;

    const isWithdrawDisabled = !selectedWithdrawal?.isVested || props.disabled;

    const callToAction = (
        <>
            <Settings title="Add Collateral"/>
            <div className="wc-body">
                <div className="form-group with-max-btn">
                    <TextInput
                        className={cx(tcx('form-control'), 'with-max-btn')}
                        name={TextInputName.NegativeCollateral}
                        onChange={onInputChange}
                        placeholder={`Amount of ${token}`}
                        type="number"
                        value={inputAmount}
                        disabled={isCollateralLoading || isWithdrawLoading || props.disabled}
                    />
                    <button
                        onClick={handleMaxBtnClick}
                        className="button-1 max-btn"
                        disabled={props.disabled}
                    >
                        MAX
                    </button>
                </div>
                <div
                    className={tcx('side-info-box')}
                    style={{
                        marginBottom: '0',
                    }}
                >
                    <ul>
                        <li>
                            <label>{token} Available:</label>
                            <FormattedValue value={walletValues.balance} isLoading={isCollateralLoading}/>
                        </li>
                        <li>
                            <label>{token} in USD:</label>
                            <FormattedValue
                                isCurrency={true}
                                isLoading={isCollateralLoading}
                                value={String(Number(inputAmount) * Number(globalValues.ethPriceInUsd))}
                            />
                        </li>
                        <li>
                            <label>Principal and Rewards in USD:</label>
                            <FormattedValue
                                isCurrency={true}
                                isLoading={isCollateralLoading}
                                value={String(Number(inputAmount) * Number(globalValues.ethPriceInUsd) * (1 + rewardsPreview.totalRoi))}
                            />
                        </li>
                    </ul>
                    <ConnectButton
                        className={cx('mt-2', tcx('button-1'))}
                        disabled={isAddCollateralDisabled || isWithdrawLoading}
                        onClick={onAddCollateral}
                        onDone={resetCollateralLoadingMessages}
                        customLoadingMessage={collateralCustomLoadingMessage}
                        isLimitExceeded={limitMessage.length > 0}
                        limitMessage={limitMessage}
                    >
                        Add Collateral
                    </ConnectButton>
                </div>
            </div>
        </>
    );

    const withdraw = (
        <>
            <div className={cx(Styles.withdrawHeader, 'wc-title')}>
                <h3>Withdraw Rewards</h3>
            </div>
            <div className={cx(Styles.withdrawContent, 'wc-body mb-3')}>
                <Carousel
                    items={rewards}
                    onChange={onCarouselChange}
                >
                    {(item): React.ReactElement => {
                        return (
                            <div className="item">
                                <div className={tcx('si-list')}>
                                    <ul>
                                        <li>
                                            <b>BankX (in USD):</b>
                                            <FormattedValue value={item.bankx} isCurrency isLoading={isWithdrawLoading} disableTooltip/>
                                        </li>
                                        <li>
                                            <b>XSD (in USD):</b>
                                            <FormattedValue value={item.xsd} isCurrency isLoading={isWithdrawLoading} disableTooltip/>
                                        </li>
                                        <li>
                                            <b>Withdrawal Date:</b>
                                            <span>{item.vestingDate}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        );
                    }}
                </Carousel>
            </div>
            <ConnectButton
                disabled={isWithdrawDisabled || isCollateralLoading}
                onClick={onWithdraw}
                onDone={resetWithdrawLoadingMessages}
                className={cx(Styles.withdrawButton, 'withdraw-margin', tcx('button-1'))}
                customLoadingMessage={withdrawalCustomLoadingMessage}
            >
                Withdraw
            </ConnectButton>
        </>
    );

    const summary = (
        <div className="px-2">
            <div
                className={cx(tcx('side-info-box'), tcx('collateral-box'), 'dark-gray-bg')}
                style={{ marginBottom: '0' }}
            >
                <ul>
                    <li>
                        <label>Total BankX At Maturity (USD):</label>
                        <FormattedValue value={rewardSummary.bankxTotal} isCurrency isLoading={isWithdrawLoading}/>
                    </li>
                    <li>
                        <label>Total XSD at Maturity (USD):</label>
                        <FormattedValue value={rewardSummary.xsdTotal} isCurrency isLoading={isWithdrawLoading}/>
                    </li>
                    <li>
                        <label>BankX Vested Now (USD):</label>
                        <FormattedValue value={rewardSummary.bankxVested} isCurrency isLoading={isWithdrawLoading}/>
                    </li>
                    <li>
                        <label>XSD Vested Now (USD):</label>
                        <FormattedValue value={rewardSummary.xsdVested} isCurrency isLoading={isWithdrawLoading}/>
                    </li>
                </ul>
            </div>
        </div>
    );

    const rewardsInfo = (
        <>
            <div
                className={tcx('bu-wrap')}
                style={{ padding: '10px 15px 5px' }}
            >
                <b> The system has a collateral deficit.</b>
                <b> Add collateral to get BankX tokens and the XSD stablecoin as a reward.</b>
            </div>
            <div
                className={cx(tcx('si-list'), 'pr-20')}
                style={{ padding: '0 15px 15px' }}
            >
                <ul>
                    <li>
                        <b>ROI in BankX:</b>
                        <FormattedValue value={rewardsPreview.bankxRoi} isLoading={isCollateralLoading} isRaw={true}/>
                    </li>
                    <li>
                        <b>ROI in XSD:</b>
                        <FormattedValue value={rewardsPreview.xsdRoi} isLoading={isCollateralLoading} isRaw={true}/>
                    </li>
                    <li>
                        <b>Term:</b>
                        <FormattedValue value={collateralInfo.vestingTime} isLoading={isCollateralLoading} isRaw={true}/>
                    </li>
                    <li>
                        <b>Max you can add:</b>
                        <FormattedValue value={String(collateralInfo.maxContribution)} isCurrency isLoading={isCollateralLoading}/>
                    </li>
                </ul>
            </div>
        </>
    );

    return (
        <RewardsGroup
            callToAction={callToAction}
            rewardsInfo={rewardsInfo}
            summary={summary}
            withdraw={withdraw}
        />
    );
}
