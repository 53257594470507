import cx from 'classnames';
import { ConnectButton, ConnectButtonText } from 'components/ConnectButton/ConnectButton';
import { FormattedValue } from 'components/FormattedValue';
import { Settings } from 'components/Settings';
import { TextInput, useTextInputContext } from 'components/TextInput';
import { TextInputName } from 'components/TextInput/types';
import { useAppSettings } from 'context/AppSettingsProvider';
import { useGlobalValues } from 'context/ContractValuesProvider';
import { useWeb3Context } from 'context/Web3Provider';
import {
    buyBackBankx,
    buyBackXSD,
    calculateBuyBack,
} from 'helpers/collateral';
import { isValidInput } from 'helpers/input';
import { TokenLabels } from 'helpers/labels';
import { Logger } from 'helpers/logging';
import { useCustomLoadingMessage } from 'hooks/useCustomLoadingMessages';
import React from 'react';
import { useState } from 'react';

import Styles from './styles.module.sass';

interface PositiveCollateralProps {
    disabled?: boolean;
}

export function PositiveCollateral({ disabled: isForcedDisabled = false }: PositiveCollateralProps) {
    const { calculateSlippageForOutput, getThemedClass: tcx } = useAppSettings();
    const globalValues = useGlobalValues();
    const walletValues = globalValues.walletValues;

    const { useInputState } = useTextInputContext();
    const [inputAmount, setInputAmount] = useInputState(TextInputName.PositiveCollateral);
    const [selectedToken, setSelectedToken] = useState(TokenLabels.Bankx);

    const isBankxSelected = selectedToken === TokenLabels.Bankx;

    const expectedEth = calculateBuyBack(
        inputAmount,
        isBankxSelected ? globalValues.bankXTokenPrice : globalValues.xsdPrice,
        globalValues.ethPriceInUsd,
    );

    const { contracts, getProvider } = useWeb3Context();
    const {
        bankxTokenContract,
        collateralPoolContract,
        xsdTokenContract,
        pidContract,
        proxyContract,
    } = contracts;
    const provider = getProvider();

    const {
        isLoading,
        message: customLoadingMessage,
        next: goToNextLoadingMessage,
        reset: resetLoadingMessages,
    } = useCustomLoadingMessage();

    async function onBuyBackClick(): Promise<void> {
        try {
            // 4 steps: price check -> approve -> buy back -> wait
            resetLoadingMessages(4);

            if (selectedToken === TokenLabels.Bankx) {
                await buyBackBankx({
                    bankxInputAmount: inputAmount,
                    bankxTokenContract,
                    collateralPoolContract,
                    provider,
                    expectedEth: calculateSlippageForOutput(expectedEth || ''),
                    goToNextLoadingMessage,
                    pidContract,
                    proxyContract,
                    chain: globalValues.chain,
                });
            }

            if (selectedToken === TokenLabels.Xsd) {
                await buyBackXSD({
                    xsdInputAmount: inputAmount,
                    xsdTokenContract,
                    collateralPoolContract,
                    provider,
                    expectedEth: calculateSlippageForOutput(expectedEth || ''),
                    goToNextLoadingMessage,
                    pidContract,
                    proxyContract,
                    chain: globalValues.chain,
                });
            }
        } catch(e) {
            Logger.error(e);
            throw e;
        }
    }

    const isXsd = selectedToken === TokenLabels.Xsd;
    const buyBack = globalValues.buyBack;
    const maxToAdd = isXsd ? buyBack.maxXsdInput : buyBack.maxBankxInput;
    const availableTokens = isXsd ? walletValues.xsd : walletValues.bankx;
    const hasValidInput = isValidInput(inputAmount);

    const customLimitMessage = function(): string {
        const isBalanceExceeded = Number(inputAmount) > Number(availableTokens);
        const isLimitExceeded = Number(inputAmount) > Number(maxToAdd);

        if (hasValidInput && isBalanceExceeded) {
            return ConnectButtonText.NotEnoughBalance;
        }
        if(hasValidInput && isLimitExceeded) {
            return ConnectButtonText.MaxAmountExceeded;
        }
        return ConnectButtonText.Empty;
    }();

    // TODO: remove after contract is updated to support xsd buy back
    const isDisabled = isForcedDisabled || Number(inputAmount) > maxToAdd || Number(inputAmount) <= 0;

    function handleInputChange(e: React.ChangeEvent<HTMLInputElement>): void {
        setInputAmount(e.currentTarget.value);
    }

    function handleMaxBtnClick(): void {
        const amount = Math.min(maxToAdd, Number(availableTokens));
        setInputAmount(String(amount));
    }

    function handleTokenChange(token: TokenLabels): void {
        setInputAmount('');
        setSelectedToken(token);
    }

    return (
        <div className={tcx('main-card')}>
            <div className={tcx('main-card-inner')}>
                <Settings title="Buy Back Collateral" className={Styles.header}/>
                <div className="main-card-body">
                    <div className={cx(Styles.toggle, 'mb-3')}>
                        <button
                            onClick={(): void => handleTokenChange(TokenLabels.Bankx)}
                            className={cx('button-tab button-tab-sm mr-2', Styles.pill, { [tcx('button-tab-active')]: selectedToken === TokenLabels.Bankx })}
                            style={{ marginRight: '0px' }}
                        >
                            BankX
                        </button>
                        <button
                            onClick={(): void => handleTokenChange(TokenLabels.Xsd)}
                            className={cx('button-tab button-tab-sm', Styles.pill, { [tcx('button-tab-active')]: selectedToken === TokenLabels.Xsd })}
                        >
                            XSD
                        </button>
                    </div>
                    <form>
                        <div className="form-group with-max-btn">
                            <TextInput
                                className={cx(tcx('form-control'), 'with-max-btn')}
                                name={TextInputName.PositiveCollateral}
                                onChange={handleInputChange}
                                placeholder="Enter Amount"
                                type="number"
                                value={inputAmount}
                                disabled={isLoading}
                            />
                            <button onClick={handleMaxBtnClick} className="button-1 max-btn">MAX</button>
                        </div>

                        <div className={tcx('side-info-box2')}>
                            <ul>
                                <li>
                                    <label>Max Buy Back:</label>
                                    <FormattedValue value={String(buyBack.excessAmountInUsd)} isLoading={isLoading} isCurrency />
                                </li>
                                <li>
                                    <label>Max {selectedToken}:</label>
                                    <FormattedValue value={String(maxToAdd)} isLoading={isLoading} />
                                </li>
                                <li>
                                    <label>{selectedToken} Available:</label>
                                    <FormattedValue value={availableTokens} isLoading={isLoading}/>
                                </li>
                            </ul>
                            <p className="text-left text-sm mt-2">
                                The system has excess collateral.
                                <br/>
                                Buy Collateral using BankX Tokens or XSD at equal value.
                            </p>
                        </div>

                        <div
                            className={cx(tcx('side-info-box'), 'dark-gray-bg')}
                        >
                            <ul>
                                <li>
                                    <label>{walletValues.tokenLabel} You Receive:</label>
                                    <FormattedValue value={expectedEth || '-'} />
                                </li>
                            </ul>
                        </div>
                        <div className="btn-wrap">
                            <ConnectButton
                                className={tcx('button-1')}
                                disabled={!isValidInput(inputAmount) || isDisabled}
                                onClick={onBuyBackClick}
                                onDone={resetLoadingMessages}
                                customLoadingMessage={customLoadingMessage}
                                isLimitExceeded={customLimitMessage.length > 0}
                                limitMessage={customLimitMessage}
                            >
                                Buy Back
                            </ConnectButton>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
