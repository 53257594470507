import 'react-datepicker/dist/react-datepicker.css';

import { CalendarIcon } from 'assets/CalendarIcon';
import cx from 'classnames';
import { Carousel } from 'components/Carousel';
import CDFinancialList from 'components/CDFinancialList';
import { ConnectButton } from 'components/ConnectButton/ConnectButton';
import { FormattedValue } from 'components/FormattedValue';
import { DashboardLayout } from 'components/layouts/DashboardLayout';
import { Settings } from 'components/Settings';
import { TextInput, useTextInputContext } from 'components/TextInput';
import { TextInputName } from 'components/TextInput/types';
import { useAppSettings } from 'context/AppSettingsProvider';
import { useGlobalValues } from 'context/ContractValuesProvider';
import { useWeb3Context } from 'context/Web3Provider';
import type { StakeInfo } from 'helpers/certificateOfDeposit';
import {
    getCDFinancials,
    getCDRate,
    getStakeInfo,
    stakeEnd,
    stakeStart,
} from 'helpers/certificateOfDeposit';
import { daysToDate } from 'helpers/date';
import { formatCommas, formatPrice } from 'helpers/formatting';
import { Logger } from 'helpers/logging';
import type { TNFT } from 'helpers/nft';
import {
    getNFTLabel, getNFTsInLimbo, getNFTsOwned, unstakeNFTsInLimbo
} from 'helpers/nft';
import { getValidInputValue } from 'helpers/number';
import { useCustomLoadingMessage } from 'hooks/useCustomLoadingMessages';
import { useWallet } from 'hooks/useWallet';
import { every } from 'lodash';
import React, {
    useCallback, useEffect, useMemo, useState
} from 'react';
import DatePicker from 'react-datepicker';
import { default as ReactSelect, MultiValue } from 'react-select';

export function Certificate(): React.ReactElement {
    const [stakeEndDate, setStakeEndDate] = useState<Date | null>(null);
    const [stakeInfo, setStakeInfo] = useState<StakeInfo[]>([]);
    const [currentCarouselStake, setCurrentCarouselStake] =
    useState<StakeInfo | null>(null);
    const [cdRate, setCdRate] = useState<number | undefined>();
    const [ownedNFTs, setOwnedNFTs] = useState<TNFT[]>([]);
    const [nftsInLimbo, setNFTsInLimbo] = useState<number>(0);
    const [isLoadingOwnedNFTs, setIsLoadingOwnedNFTs] = useState(false);
    const { getThemedClass: tcx } = useAppSettings();
    const [selectedNFTsToStake, setSelectedNFTsToStake] = useState<
    MultiValue<{
        value: number
        label: string
    }>
    >([]);
    const [hasEarlyWithdrawalWarning, setHasEarlyWithdrawalWarning] =
    useState(false);
    const {
        isLoading,
        message: customLoadingMessage,
        next: goToNextLoadingMessage,
        reset: resetLoadingMessages,
    } = useCustomLoadingMessage();

    /* UI state
     -------------------------------------------- */
    const { useInputState } = useTextInputContext();
    const [bankxAmountToStake, setBankxAmountToStake] = useInputState(
        TextInputName.Certificate
    );
    const globalValues = useGlobalValues();
    const walletValues = globalValues.walletValues;

    const {
        certificateOfDepositInfo,
        bankXTokenPrice,
        nftInfo,
        ...restOfGlobalValues
    } = globalValues;

    const { totalSupply } = nftInfo || {};

    const currentDay = certificateOfDepositInfo?.currentDay || '-';
    const longerPaysBetter = certificateOfDepositInfo?.LPB || '-';
    const longerPaysBetterBonusPercent =
    certificateOfDepositInfo?.LPBBonusPercent || '-';

    /* wallet and contracts
      -------------------------------------------- */
    const {
        address: walletAddress, isConnected: isWalletConnected
    } = useWallet();

    const { getProvider, contracts } = useWeb3Context();
    const provider = getProvider();
    const {
        cdContract, bankxTokenContract, nftBonusContract, bankxNftContract
    } =
    contracts;

    /* fetch stake info */
    const fetchStakeInfo = useCallback(
        (stakeId?: number) => {
            if (!walletAddress) {
                return;
            }
            getStakeInfo(walletAddress, cdContract, nftBonusContract, provider)
                .then((_stakeInfo) => {
                    if (Array.isArray(_stakeInfo)) {
                        setStakeInfo(_stakeInfo);
                        if (typeof stakeId === 'number') {
                            setCurrentCarouselStake(_stakeInfo[stakeId]);
                        } else {
                            setCurrentCarouselStake(_stakeInfo[_stakeInfo.length - 1]);
                        }
                    }
                })
                .catch((error) => {
                    throw new Error('Error: unable to get stake info', {
                        cause: error,
                    });
                });
        },
        [
            provider,
            cdContract,
            walletAddress,
            nftBonusContract
        ]
    );

    /* CoD Staking info */
    useEffect(
        function () {
            fetchStakeInfo(0);
        },
        [
            fetchStakeInfo,
            walletAddress,
            cdContract,
            provider
        ]
    );

    /* fetch CD rate */
    useEffect(() => {
        if (walletAddress) {
            getCDRate(cdContract, provider)
                .then((_cdRate) => {
                    setCdRate(_cdRate);
                })
                .catch((error) => {
                    throw new Error('Certificate: Error getting CD rate', { cause: error });
                });
        }
    }, [
        walletAddress,
        cdContract,
        provider,
        bankXTokenPrice
    ]);

    const fetchNFTsOwned = useCallback(() => {
        if (!walletAddress || typeof totalSupply === 'undefined') {
            return;
        }
        setIsLoadingOwnedNFTs(true);
        getNFTsOwned(
            walletAddress,
            bankxNftContract,
            nftBonusContract,
            totalSupply,
            false
        )
            .then((ownedNftInfo) => {
                setIsLoadingOwnedNFTs(false);
                setOwnedNFTs(ownedNftInfo);
            })
            .catch((error) => {
                setIsLoadingOwnedNFTs(false);
                Logger.error('Certificate: Error getting NFT info', {
                    cause: error,
                });
            });

        getNFTsInLimbo(walletAddress, nftBonusContract)
            .then((nftsInLimbo) => {
                setNFTsInLimbo(nftsInLimbo);
            })
            .catch((error) => {
                Logger.error('Certificate: Error getting NFTs in limbo', {
                    cause: error,
                });
            });
    }, [
        walletAddress,
        bankxNftContract,
        nftBonusContract,
        totalSupply,
    ]);

    /* fetch NFT info */
    useEffect(
        () => fetchNFTsOwned(),
        [
            fetchNFTsOwned,
            walletAddress,
            bankxNftContract,
            nftBonusContract,
            totalSupply
        ]
    );

    /* calculations and formatting
     -------------------------------------------- */
    const canStake = every([
        isWalletConnected,
        !isLoading,
        !!bankxAmountToStake,
        Number(bankxAmountToStake) > 0,
        !!stakeEndDate,
    ]);

    function getDaysLeftInStakeCopy() {
        const currentDayNumber = Number(currentDay);

        if (!currentCarouselStake || isNaN(currentDayNumber)) {
            return null;
        }

        const daysLeftInCarouselStake = ((currentCarouselStake.lockedDay) + (currentCarouselStake.stakedDays)) - currentDayNumber;

        if (daysLeftInCarouselStake > 0) {
            return (
                <>
                    <p className={tcx('doesNotIncludeText')}>
                        <b>You have {daysLeftInCarouselStake} day(s) until you can claim with no penalty.</b>
                    </p>
                    <p className={tcx('doesNotIncludeText')}>
                        <b>Your claim date is: {new Date(Date.now() + daysLeftInCarouselStake * 24 * 60 * 60 * 1000).toLocaleDateString()}</b>
                    </p>
                </>
            );
        }

        if (daysLeftInCarouselStake <= 0 && daysLeftInCarouselStake > -14) {
            const daysLeftInGracePeriod = daysLeftInCarouselStake + 14;
            return (
                <p className={tcx('doesNotIncludeText')}>
                    <b>You have {daysLeftInGracePeriod} day(s) left in the 14-day grace period to claim with no penalty.</b>
                </p>
            );
        }

        return (
            <p className={tcx('doesNotIncludeText')}>
                <b>Claim your tokens immediately! <br />You are in the penalty and losing 1% per week.</b>
            </p>
        );

    }

    /* handlers
      -------------------------------------------- */
    async function handleStake() {
        let daysForStake = 0;

        if (!walletAddress || !cdRate) {
            return;
        }
        try {
            if (stakeEndDate) {
                daysForStake = daysToDate(stakeEndDate);
            }

            await stakeStart(
                Number(bankxAmountToStake),
                daysForStake,
                selectedNFTsToStake.map(({ label }) => Number(label)),
                cdContract,
                bankxTokenContract,
                bankxNftContract,
                nftBonusContract,
                provider,
                walletAddress,
                goToNextLoadingMessage,
                resetLoadingMessages
            );
        } finally {
            fetchStakeInfo();
            fetchNFTsOwned();
            setSelectedNFTsToStake([]);
            setStakeEndDate(null);
            resetLoadingMessages();
        }
    }

    function onBankxStakeChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const value = e.target.value;
        setBankxAmountToStake(getValidInputValue(value));
    }

    function handleMaxBtnClick() {
        const maxAmountRaw = parseFloat(walletValues.bankx);
        const maxAmountFormatted = Number.isInteger(maxAmountRaw)
            ? maxAmountRaw.toString()
            : walletValues.bankx.replace(/\.0$/, '');

        setBankxAmountToStake(maxAmountFormatted);
    }

    async function handleClaim() {
        if (!currentCarouselStake) {
            return;
        }

        const currentIndex = stakeInfo.findIndex((_stake) => {
            return _stake.stakeId === currentCarouselStake.stakeId;
        });

        try {
            await stakeEnd(
                currentIndex,
                currentCarouselStake.stakeId,
                cdContract,
                provider,
                goToNextLoadingMessage,
                resetLoadingMessages
            );
        } catch (error) {
            Logger.error('Error claiming stake');
            resetLoadingMessages();
            throw error;
        } finally {
            setHasEarlyWithdrawalWarning(false);
            fetchNFTsOwned();
            window.location.reload();
        }
    }

    const isEarlyWithdrawal = useMemo(() => {
        if (!currentCarouselStake || !certificateOfDepositInfo) {
            return false;
        }
        const endDay =
      currentCarouselStake.lockedDay + currentCarouselStake.stakedDays;

        return Number(certificateOfDepositInfo.currentDay) < endDay;
    }, [currentCarouselStake, certificateOfDepositInfo]);

    useEffect(() => {
        setHasEarlyWithdrawalWarning(false);
    }, [currentCarouselStake]);

    const { bankXAtMaturity: currentStakeBankXAtMaturity } = currentCarouselStake
        ? getCDFinancials({
            bankX: Number(
                ((Number(currentCarouselStake.stakedXs) || 0) / 100_000_000).toFixed(
                    2
                )
            ),
            daysStaked: currentCarouselStake.stakedDays,
            longerPaysBetterBonusPercent: Number(longerPaysBetterBonusPercent),
            longerPaysBetter: Number(longerPaysBetter),
            nftCount: currentCarouselStake.nftCount || 0,
        })
        : {
            bankXAtMaturity: 0,
        };

    Logger.log('Certificate: ', {
        bankxAmountToStake,
        nftsInLimbo,
        isEarlyWithdrawal,
        ownedNFTs,
        selectedNFTsToStake,
        globalValues,
        stakeEndDate,
        currentCarouselStake,
        stakeInfo,
        certificateOfDepositInfo,
        currentDay,
        longerPaysBetter,
        longerPaysBetterBonusPercent,
        cdRate,
        restOfGlobalValues,
    });

    async function handleUnstakeNFTInLimbo() {
        await unstakeNFTsInLimbo(walletAddress || '', nftBonusContract, provider);
    }

    function getOwnedNFTsComponent() {
        if (!walletAddress || typeof totalSupply === 'undefined') {
            return null;
        }

        // Loading animation
        if (isLoadingOwnedNFTs) {
            return (
                <div
                    style={{
                        textAlign: 'left',
                    }}
                >
                    <img
                        style={{
                            height: '18px',
                            width: 'fit-content !important',
                            margin: '0 !important',
                        }}
                        src='/images/loading/ellipsis.svg'
                        alt='loading'
                    />
                    <span
                        style={{
                            paddingLeft: 8,
                            fontSize: 13,
                            color: 'black',
                            fontWeight: 600,
                        }}
                    >
                  Fetching NFT’s
                    </span>
                </div>
            );
        }

        // Link to NFTs (if none owned)
        if (!ownedNFTs.length) {
            return (
                <div
                    style={{
                        textAlign: 'left',
                    }}
                >
                    <span
                        style={{
                            fontSize: 12,
                            color: '#1882c4',
                            fontWeight: 600,
                        }}
                    >
                        <a href='/#/nft'>Click &apos;NFT&apos; for more rewards</a>
                    </span>
                </div>
            );
        }

        // NFT select input
        if (ownedNFTs.length) {
            return (
                <div className='form-group'>
                    <ReactSelect
                        onChange={(selectedOptions) => {
                            if (selectedOptions.length <= 5 - nftsInLimbo) {
                                setSelectedNFTsToStake(selectedOptions);
                            } else {
                                const [, ...newSelectedNFTsToStake] = selectedOptions;
                                setSelectedNFTsToStake(newSelectedNFTsToStake);
                            }
                        }}
                        value={selectedNFTsToStake}
                        options={ownedNFTs.map((nft: TNFT, index: number) => ({
                            value: index,
                            label: String(nft.id),
                        }))}
                        getOptionLabel={(option) => getNFTLabel(Number(option.label))}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        placeholder='Lock NFT - Boost Rewards'
                        className={tcx('selectDropdown')}
                        isClearable={false}
                        styles={{
                            menuList: (baseStyles) => {
                                return {
                                    ...baseStyles,
                                    background: 'white',
                                };
                            },
                            indicatorsContainer: (baseStyles) => {
                                return {
                                    ...baseStyles,
                                    background: '#38436414',
                                    color: '#01303c',
                                    width: 60,
                                    paddingLeft: 12,
                                    borderTopRightRadius: 9,
                                    borderBottomRightRadius: 9,
                                };
                            },
                            dropdownIndicator: (baseStyles) => {
                                return {
                                    ...baseStyles,
                                    color: '#01303c',
                                };
                            },

                            indicatorSeparator: (baseStyles) => {
                                return {
                                    ...baseStyles,
                                    display: 'none',
                                };
                            },
                            option: (baseStyles, { isFocused }) => {
                                return {
                                    ...baseStyles,
                                    background: isFocused ? 'rgb(24, 130, 197)' : 'white',
                                    color: 'rgb(33, 37, 41)',
                                };
                            },
                            multiValueLabel: (baseStyles) => {
                                return {
                                    ...baseStyles,
                                    marginBottom: 0,
                                };
                            },
                            control: (baseStyles, { isFocused }) => {
                                return {
                                    ...baseStyles,
                                    ...(isFocused && {
                                        boxShadow: '0 0 0 0.2rem rgb(0 123 255 / 25%)',
                                        border: 'none',
                                    }),
                                    borderRadius: 9,
                                };
                            },
                            placeholder: (baseStyles) => {
                                return {
                                    ...baseStyles,
                                    paddingLeft: 15,
                                    color: '#283280',
                                    opacity: 0.4,
                                };
                            },
                        }}
                    />
                </div>
            );
        }

    }

    return (
        <DashboardLayout>
            <div className={cx('content-wrapper', tcx('certificate-page'))}>
                <div className={cx(tcx('main-card'), tcx('card-bg'))}>
                    <div className='row'>
                        <div className='col-md-6 curved-transition-container'>
                            <div
                                className={cx(tcx('white-card'), tcx('curved-transition'))}
                            >
                                <Settings
                                    title='Token Lockup Rewards'
                                    className='main-card-header-less-margin-bottom'
                                />
                                <div className='wc-body'>
                                    <div className='form-group with-max-btn'>
                                        <TextInput
                                            className={cx(tcx('form-control'), 'with-max-btn')}
                                            onChange={onBankxStakeChange}
                                            name={TextInputName.Certificate}
                                            placeholder='BankX Tokens'
                                            type='number'
                                            value={bankxAmountToStake}
                                            disabled={isLoading}
                                        />
                                        <button
                                            onClick={handleMaxBtnClick}
                                            className='button-1 max-btn'
                                        >
                                            MAX
                                        </button>
                                    </div>
                                    <div
                                        className={tcx('side-info-box2')}
                                        style={{
                                            marginBottom: 8,
                                            marginTop: 8,
                                        }}
                                    >
                                        <ul>
                                            <li>
                                                <label>BankX Tokens Available:</label>
                                                <FormattedValue value={walletValues.bankx} />
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='form-group datepicker-icon '>
                                        <DatePicker
                                            minDate={new Date()}
                                            className={tcx('datepicker form-control')}
                                            selected={stakeEndDate}
                                            placeholderText='How long in days'
                                            onChange={setStakeEndDate}
                                        />
                                        <div className={tcx('datepicker-btn')}>
                                            <CalendarIcon />
                                        </div>
                                    </div>
                                    {getOwnedNFTsComponent()}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 mb-4 squished'>
                            <div className={cx(tcx('white-card'))}>
                                <div className='wc-title'>
                                    <h3>Financials</h3>
                                </div>
                                <div className='wc-body'>
                                    <div className={tcx('si-list')}>
                                        <ul className='mr-2'>
                                            <li>
                                                <b>Day:</b>
                                                <span>{currentDay}</span>
                                            </li>
                                            <br />
                                            {stakeEndDate && bankxAmountToStake && (
                                                <CDFinancialList
                                                    provider={provider}
                                                    cdContract={cdContract}
                                                    nftCount={selectedNFTsToStake.length + nftsInLimbo}
                                                    bankxAmountToStake={Number(bankxAmountToStake)}
                                                    stakeEndDate={stakeEndDate}
                                                    longerPaysBetterBonusPercent={
                                                        longerPaysBetterBonusPercent
                                                    }
                                                    longerPaysBetter={longerPaysBetter}
                                                    nftsInLimbo={nftsInLimbo}
                                                />
                                            )}
                                            {nftsInLimbo > 0 && (
                                                <>
                                                    <li
                                                        title="Your last staking attempt was not completed. These NFT's will be locked on your next CD lockup."
                                                        style={{
                                                            cursor: 'help',
                                                        }}
                                                    >
                                                        <b
                                                            style={{
                                                                color: '#e85f5f',
                                                            }}
                                                        >
                                                        NFT&apos;s in Limbo:
                                                        </b>
                                                        <span
                                                            style={{
                                                                color: '#e85f5f',
                                                            }}
                                                        >
                                                            {nftsInLimbo}
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <b>
                                                            {/* eslint-disable-next-line */}
                                                            <a href="#" onClick={async e => {
                                                                e.preventDefault();
                                                                handleUnstakeNFTInLimbo();
                                                            }}>CLICK HERE to Retrieve NFT&apos;s in Limbo</a>
                                                        </b>
                                                    </li>
                                                </>
                                            )}
                                            <li title='X = 1/100M of a BankX token.  CD Price = Share Price.'>
                                                <b>Token Lockup Rewards Price in X&apos;s:</b>
                                                <span>{cdRate ? cdRate / 1e-8 : '-'}</span>
                                            </li>

                                            <li title='BankX at Maturity / CD Price'>
                                                <b>Locked X&apos;s:</b>
                                                {cdRate && (
                                                    <span>
                                                        {bankxAmountToStake
                                                            ? formatCommas(
                                                                Number(bankxAmountToStake) / cdRate
                                                            )
                                                            : '-'}
                                                    </span>
                                                )}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={cx('row', tcx('row-status'))}
                        style={{
                            paddingTop: 0,
                            paddingBottom: 0,
                        }}
                    >
                        <div className='col-md-6'>
                            <div
                                className={tcx('si-list')}
                                style={{ padding: '10px 15px' }}
                            >
                                <ul>
                                    <li>
                                        <b>Start Day:</b>
                                        <span>{currentDay}</span>
                                    </li>
                                    {stakeEndDate && (
                                        <>
                                            <li>
                                                <b>Last Full Day:</b>
                                                <span>
                                                    {Number(currentDay) + daysToDate(stakeEndDate) - 1}
                                                </span>
                                            </li>
                                            <li>
                                                <b>End Date:</b>
                                                <span>
                                                    {Number(currentDay) + daysToDate(stakeEndDate)}
                                                </span>
                                            </li>
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className={`${tcx('bu-wrap')}`}>
                                <ConnectButton
                                    className={tcx('button-1')}
                                    disabled={!canStake}
                                    onClick={handleStake}
                                    customLoadingMessage={customLoadingMessage}
                                >
                                    Lockup {nftsInLimbo ? ' with NFTs in Limbo' : ''}
                                </ConnectButton>
                            </div>
                        </div>
                    </div>
                    {stakeInfo.length > 0 && (
                        <div className='row'>
                            <div className='col-md-6'>
                                <div
                                    className={cx(
                                        tcx('white-card'),
                                        'index-0',
                                        hasEarlyWithdrawalWarning && 'hasEarlyWithdrawalWarning'
                                    )}
                                    style={{
                                        paddingTop: hasEarlyWithdrawalWarning ? 24 : 10,
                                        marginBottom: 0,
                                        paddingBottom: 8,
                                    }}
                                >
                                    <div className='wc-title'>
                                        <h3>Active BankX Token Lockup Rewards</h3>
                                    </div>
                                    <Carousel
                                        items={stakeInfo}
                                        onChange={(stake) => setCurrentCarouselStake(stake)}
                                    >
                                        {({
                                            lockedDay,
                                            stakedDays,
                                            stakedXs,
                                            stakeShares,
                                            unlockedDay,
                                            nftCount = 0,
                                        }): React.ReactElement => {
                                            const endDay = lockedDay + stakedDays;
                                            const rawProgressPercent =
                          ((Number(currentDay) - lockedDay) /
                            (endDay - lockedDay)) *
                          100;
                                            const progressPercent =
                          rawProgressPercent > 100
                              ? 100
                              : rawProgressPercent < 0
                                  ? 0
                                  : rawProgressPercent.toFixed(0);
                                            const stakedBankX = Number(
                                                (Number(stakedXs) / 100_000_000).toFixed(2)
                                            );

                                            const stakedSharesInUsd =
                          Number(bankXTokenPrice) *
                          Number(currentStakeBankXAtMaturity);

                                            Logger.log(
                                                'Certificate: selected carousel financials',
                                                {
                                                    stakedBankX,
                                                    stakedSharesInUsd,
                                                    lockedDay,
                                                    stakedDays,
                                                    stakedXs,
                                                    stakeShares,
                                                    unlockedDay,
                                                }
                                            );

                                            return (
                                                <div className='item'>
                                                    <div className={tcx('si-list')}>
                                                        <ul>
                                                            <li>
                                                                <b>Start Day:</b>
                                                                <span>{lockedDay}</span>
                                                            </li>
                                                            <li>
                                                                <b>End Day:</b>
                                                                <span>{endDay}</span>
                                                            </li>
                                                            <li>
                                                                <b>Progress:</b>
                                                                <span>
                                                                    {isNaN(progressPercent as number)
                                                                        ? '-'
                                                                        : `${progressPercent}%`}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <b>Principal (Bankx):</b>
                                                                <span>{formatCommas(stakedBankX)}</span>
                                                            </li>
                                                            <li>
                                                                <b>Locked X&apos;s:</b>
                                                                {cdRate && (
                                                                    <span>
                                                                        {formatCommas(
                                                                            Number(stakedBankX) / cdRate
                                                                        )}
                                                                    </span>
                                                                )}
                                                            </li>
                                                            <li>
                                                                <b>NFTs locked:</b>
                                                                <span>{nftCount}</span>
                                                            </li>
                                                            <li>
                                                                <b>BankX at Maturity:</b>
                                                                <span>
                                                                    {formatCommas(currentStakeBankXAtMaturity)}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <b>BankX at Maturity (USD):</b>
                                                                <span>{formatPrice(stakedSharesInUsd)}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            );
                                        }}
                                    </Carousel>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className={tcx('white-card')}>
                                    <div className='wc-title'>
                                        <h3>Claim</h3>
                                    </div>
                                    <div className='wc-body'>
                                        <div className={tcx('side-info-box2')}>
                                            <ul>
                                                <li>
                                                    <label>BankX:</label>
                                                    <span>
                                                        {Number(currentStakeBankXAtMaturity).toFixed(2)}
                                                    </span>
                                                </li>
                                            </ul>
                                            <br />
                                            {getDaysLeftInStakeCopy()}
                                            <p
                                                className={tcx('doesNotIncludeText')}
                                            >
                                                 *Does not include your share of the 5.28% inflation
                                                per year.
                                            </p>
                                        </div>

                                        {!hasEarlyWithdrawalWarning ? (
                                            <ConnectButton
                                                className={tcx('button-1')}
                                                disabled={!stakeInfo.length || isLoading}
                                                onClick={async () => {
                                                    if (isEarlyWithdrawal) {
                                                        setHasEarlyWithdrawalWarning(true);
                                                    } else {
                                                        setHasEarlyWithdrawalWarning(false);
                                                        await handleClaim();
                                                    }
                                                }}
                                                customLoadingMessage={customLoadingMessage}
                                                onClickOverride={isEarlyWithdrawal}
                                            >
                                                Claim
                                            </ConnectButton>
                                        ) : (
                                            <>
                                                <button
                                                    type='button'
                                                    className={cx(tcx('button-1'), tcx('error-button'))}
                                                    style={{ marginTop: 8 }}
                                                    disabled
                                                >
                            There are penalties! Are you sure?
                                                </button>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignContent: 'baseline',
                                                    }}
                                                >
                                                    <ConnectButton
                                                        className={cx(
                                                            tcx('button-1'),
                                                            tcx('error-button')
                                                        )}
                                                        disabled={!stakeInfo.length || isLoading}
                                                        onClick={handleClaim}
                                                        customLoadingMessage={customLoadingMessage}
                                                        style={{
                                                            marginTop: 8,
                                                            marginRight: 4,
                                                            width: 'calc(50% - 4px)',
                                                        }}
                                                    >
                              Yes
                                                    </ConnectButton>
                                                    <button
                                                        type='button'
                                                        className={cx(tcx('button-1'))}
                                                        style={{
                                                            marginTop: 8,
                                                            marginLeft: 4,
                                                            width: 'calc(50% - 4px)',
                                                        }}
                                                        disabled={!stakeInfo.length || isLoading}
                                                        onClick={() =>
                                                            setHasEarlyWithdrawalWarning(false)
                                                        }
                                                    >
                              Cancel
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </DashboardLayout>
    );
}
