import cx from 'classnames';
import { useAppSettings } from 'context/AppSettingsProvider';
import { useGlobalValues } from 'context/ContractValuesProvider';
import { Logger } from 'helpers/logging';
import {
    addCommas,
    toAmount,
    toCurrency,
} from 'helpers/number';
import React, { useMemo } from 'react';
import ReactToolTip from 'react-tooltip';

import Styles from './styles.module.scss';

interface FormattedValueProps {
    isCurrency?: boolean;
    isLoading?: boolean;
    isPercentage?: boolean;
    isRaw?: boolean;
    precision?: number;
    suffix?: string;
    value: string | undefined;
    hasPlusMinus?: boolean;
    disableTooltip?: boolean;
    showDashOnEmptyString?: boolean; // when given an empty string, show a dash instead of "0"
}

const DISABLE_TOOLTIP = true;

export function FormattedValue({
    isCurrency,
    isLoading,
    isPercentage = false,
    precision = 2,
    suffix = '',
    value = '',
    hasPlusMinus = false,
    disableTooltip = false,
    isRaw = false,
    showDashOnEmptyString = false,
}: FormattedValueProps): React.ReactElement {
    const { isDarkTheme } = useAppSettings();

    const { areValuesLoading } = useGlobalValues();
    const isTooltipDisabled = useMemo((): boolean => {
        if (DISABLE_TOOLTIP) {
            return true;
        }

        const mantissa = value.split('.')[1];

        Logger.log('FormattedValue mantissa', mantissa, 'value', value);
        return disableTooltip || mantissa === undefined || mantissa.length <= 2;
    }, [disableTooltip, value]);

    const config = { precision };
    const isDashValue = showDashOnEmptyString && value === '';
    let formatted = toAmount(value, config);

    if (isCurrency) {
        formatted = toCurrency(value, precision);
    }
    if (isDashValue) {
        formatted = '-';
    }
    if (isPercentage && !isDashValue) {
        formatted = String(Number(value) * 100) + '%';
    }

    const tooltip = (isCurrency ? '$' : '') + addCommas(value);
    const isInvalid = Number.isNaN(Number(value));

    if (isLoading || areValuesLoading) {
        return <img className={cx(Styles.loading)} src="/images/loading/ellipsis.svg" alt="loading" />;
    }

    if (isRaw) {
        return <span style={{ whiteSpace: 'pre' }}>{value}</span>;
    }

    if (isInvalid) {
        return <span>-</span>;
    }

    const plusMinusPrefix = hasPlusMinus && Number(value) > 0 ? '+' : '';

    // another instance of ReactToolTip can trigger the data-tip property
    if (isTooltipDisabled) {
        return <span>{formatted}{suffix}</span>;
    }
    return (
        <>
            <span data-tip={tooltip}>{plusMinusPrefix}{formatted}{suffix}</span>
            <ReactToolTip type={isDarkTheme ? 'light' : undefined}/>
        </>
    );
}
