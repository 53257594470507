import { ethers } from 'ethers';
import { Logger } from 'helpers/logging';
import { CustomLoadingMessage } from 'hooks/useCustomLoadingMessages';

import { toBigIntString, toDecimalString } from './number';

export interface StakeInfo {
    stakeId: number;
    stakedXs: string;
    lockedDay: number;
    stakeShares: string;
    stakedDays: number;
    unlockedDay: number;
    nftCount?: number;
}

export async function stakeStart(
    stakedXs: number,
    stakedDays: number | string,
    nftIdsToStake: number[],
    CDContract: ethers.Contract,
    BankXContract: ethers.Contract,
    BankXNFTContract: ethers.Contract,
    NFTBonusContract: ethers.Contract,
    provider: ethers.providers.Web3Provider,
    walletAddress: string,
    goToNextLoadingMessage: (message?: string) => void,
    resetLoadingMessages: (numberOfSteps?: number | undefined) => void
): Promise<void> {
    resetLoadingMessages();
    const bankX = toBigIntString(stakedXs, 18);
    const signer = provider.getSigner();

    const allowanceRaw = await BankXContract.connect(signer).allowance(
        walletAddress,
        CDContract.address
    );
    const allowance = Number(toDecimalString(allowanceRaw, 18));
    const needsAllowanceApproval = allowance < stakedXs;

    const isNFTApproved = await BankXNFTContract.connect(signer).isApprovedForAll(
        walletAddress,
        NFTBonusContract.address
    );

    Logger.log('stakeStart', {
        isNFTApproved,
        nftIdsToStake,
        bankX,
        stakedXs,
        allowance,
        stakedDays,
        needsAllowanceApproval,
    });

    if (needsAllowanceApproval) {
        goToNextLoadingMessage(CustomLoadingMessage.CertificateBankXApproval);
        // owner is user's address, spender is CDContract.address
        const bankxReceipt = await BankXContract
            .connect(signer)
            .approve(CDContract.address, bankX);

        Logger.log('startStart approveReceipt', bankxReceipt);

        await bankxReceipt.wait(1);
    }

    if (!isNFTApproved && nftIdsToStake.length > 0) {
        goToNextLoadingMessage(CustomLoadingMessage.CertificateNFTApproval);
        const txReceipt = await BankXNFTContract
            .connect(signer)
            .setApprovalForAll(NFTBonusContract.address, true);

        Logger.log('stakeStart setApprovalForAll txReceipt', txReceipt);

        await txReceipt.wait(1);
    }

    // loop through NFT IDs provided and stake them
    if (nftIdsToStake.length) {
        if (nftIdsToStake.length > 1) {
            resetLoadingMessages(nftIdsToStake.length);
        }
        const lastIndex = nftIdsToStake.length > 5 ? 4 : nftIdsToStake.length - 1;

        for (let i = 0; i <= lastIndex; i++) {
            const nftId = nftIdsToStake[i];

            Logger.log('stakeStart nftID to stake', nftId);

            goToNextLoadingMessage(CustomLoadingMessage.CertificateApproveNFT);

            const nftStakeReceipt = await NFTBonusContract
                .connect(signer)
                .stakeNFT(nftId);

            Logger.log('stakeStart nftStakeReceipt', nftStakeReceipt);

            await nftStakeReceipt.wait(1);
        }
    }

    resetLoadingMessages();

    goToNextLoadingMessage(CustomLoadingMessage.Staking);

    const txReceipt = await CDContract.connect(signer).stakeStart(
        ethers.BigNumber.from(bankX).div(ethers.BigNumber.from(1e10)),
        stakedDays
    );

    Logger.log('stakeStart txReceipt', txReceipt);

    await txReceipt.wait(1);

}

export async function stakeEnd(
    stakeIndex: number | string,
    stakeId: number | string,
    CDContract: ethers.Contract,
    provider: ethers.providers.Web3Provider,
    goToNextLoadingMessage: (message?: string) => void,
    resetLoadingMessages: (numberOfSteps?: number | undefined) => void
): Promise<void> {

    Logger.log('stakeEnd stakeIndex:', stakeIndex);
    Logger.log('stakeEnd stakeId:', stakeId);

    resetLoadingMessages();

    const signer = provider.getSigner();
    goToNextLoadingMessage(CustomLoadingMessage.Claiming);
    const txReceipt = await CDContract.connect(signer).stakeEnd(
        stakeIndex,
        stakeId
    );
    Logger.log('stakeEnd txReceipt', txReceipt);
    await txReceipt.wait(1);
}

function getSingleStake(
    address: string,
    CDContract: ethers.Contract,
    signer: ethers.providers.JsonRpcSigner,
    stakeIndex: number
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
    return CDContract.connect(signer).stakeLists(address, stakeIndex);
}

export async function getStakeInfo(
    address: string,
    CDContract: ethers.Contract,
    NFTBonusContract: ethers.Contract,
    provider: ethers.providers.Web3Provider
): Promise<StakeInfo[]> {
    //XXX: get stake count, then loop over numbers to query each stake info
    const _stakeCount = await stakeCount(address, CDContract, provider);
    const stakeCountNum = Number(toBigIntString(_stakeCount, 0));

    if (stakeCountNum) {
        const signer = provider.getSigner();
        const stakeCountIndexArray = Array.from(
            { length: stakeCountNum },
            (_, index) => index
        );

        try {
            const stakesRaw = await Promise.all(stakeCountIndexArray?.map(async (index) => {
                return getSingleStake(address, CDContract, signer, index);
            })
            );

            const stakes = [];

            for (let i = 0; i < stakesRaw.length; i++) {
                const stakeArray = stakesRaw[i];
                let nftCount = 0;
                try {
                    const nftCountRaw = await NFTBonusContract.getNftsCount(stakeArray[0]);
                    nftCount = nftCountRaw.toNumber();
                } catch (err) {
                    Logger.error('ERROR fetching stakeInfo');
                }

                stakes.push({
                    stakeId: stakeArray[0],
                    stakedXs: toDecimalString(stakeArray[1], 0),
                    stakeShares: toDecimalString(stakeArray[2], 0),
                    lockedDay: stakeArray[3],
                    stakedDays: stakeArray[4],
                    unlockedDay: stakeArray[5],
                    nftCount
                });
            }
            return stakes;

        } catch (err) {
            Logger.error('ERROR fetching stakeInfo');
            return [];
        }
    }

    return [] as StakeInfo[];
}

export async function stakeCount(
    address: string,
    CDContract: ethers.Contract,
    provider: ethers.providers.Web3Provider
): Promise<number> {
    Logger.log('stakeCount address:', address);

    const signer = provider.getSigner();
    const stakedTimes = await CDContract.connect(signer).stakeCount(address);
    Logger.log('stakeCount stakedTimes', stakedTimes);

    return stakedTimes;
}

export async function getCertificateOfDepositInfo(
    CDContract: ethers.Contract
): Promise<{
        LPB: string;
        LPBBonusPercent: string;
        currentDay: string;
    }> {
    const [
        LPB,
        LPBBonusPercent,
        currentDay
    ] = await Promise.all([
        CDContract.LPB(),
        CDContract.LPBBonusPercent(),
        CDContract.currentDay(),
    ]);

    const result = {
        LPB: toBigIntString(LPB, 0),
        LPBBonusPercent: toBigIntString(LPBBonusPercent, 0),
        currentDay: toBigIntString(currentDay, 0),
    };

    Logger.log('getCertificateOfDepositInfo', result);

    return result;
}

export async function getCDRate(
    CDContract: ethers.Contract,
    provider: ethers.providers.Web3Provider
): Promise<number> {
    const signer = provider.getSigner();
    const globals = await CDContract.connect(signer).globals();

    const _cdRate = ethers.BigNumber.from(globals[2]);
    const cdRate = Number(toDecimalString(_cdRate, 5));

    return cdRate / 100_000_000;
}

export function getCDFinancials({
    bankX,
    daysStaked,
    longerPaysBetter,
    longerPaysBetterBonusPercent,
    nftCount
}: {
    bankX: number,
    daysStaked: number,
    longerPaysBetter: number,
    longerPaysBetterBonusPercent: number,
    nftCount: number
}) {
    const longerPaysBetterNumberMax = longerPaysBetterBonusPercent / 10;
    const MAX_LPB = bankX * longerPaysBetterNumberMax;

    let lpb =
        (bankX * (daysStaked - 1)) / Number(longerPaysBetter);

    const lpbNFTBonus = nftCount * 0.1 * lpb;

    // max out lpb @ 10 years
    lpb = lpb >= MAX_LPB ? MAX_LPB : lpb;

    // max out bpb @ 15 million
    let bpb = bankX * (Math.min(bankX, 150e6) / 1500e6);

    const bpbNFTBonus = nftCount * 0.1 * bpb;

    // max of 15million
    bpb = bpb >= 15_000_000 ? 15_000_000 : bpb;
    const totalNFTBonus = lpbNFTBonus + bpbNFTBonus;
    const totalRewards = bpb + lpb + totalNFTBonus;
    const bankXAtMaturity = totalRewards + bankX;

    return {
        lpb,
        bpb,
        totalNFTBonus,
        totalRewards,
        bankXAtMaturity
    };
}

export async function getStakeStartBonusXs({
    provider,
    cdContract,
    newStakedXs,
    newStakedDays,
    nftCount
}: {
    provider: ethers.providers.Web3Provider,
    cdContract: ethers.Contract,
    newStakedXs: number,
    newStakedDays: number,
    nftCount: number
    }) {
    const signer = provider.getSigner();
    const stakeStartBonusXs = await cdContract.connect(signer).getStakeStartBonusXs(newStakedXs, newStakedDays, nftCount);
    return stakeStartBonusXs
}
