import { useConnectWallet } from '@web3-onboard/react';
import cx from 'classnames';
import { DashboardLayout } from 'components/layouts/DashboardLayout';
import { PurchaseNFTBox } from 'components/PurchaseNFTBox';
import ReferralBanner from 'components/ReferralBanner';
import ReferralFooter from 'components/ReferralFooter';
import useOnScreen from 'hooks/useOnScreen';
import useRecursiveTimeout from 'hooks/useRecursiveTimeout';
import { useReferrals } from 'hooks/useReferrals';
import React, {
    useCallback, useRef, useState
} from 'react';

function BuyNFTPage() {
    const [{ wallet }] = useConnectWallet();
    const userAddress = wallet?.accounts[0].address;
    const { referrer: referrerAddress } = useReferrals();

    // video autoplay
    const [playing, setPlaying] = useState(0);
    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(ref);
    const playVideo = useCallback(() => {
        if (isVisible && !playing) {
            setPlaying(1);
        }
    }, [
        setPlaying,
        isVisible,
        playing
    ]);
    useRecursiveTimeout(playVideo, 100);

    return (
        <DashboardLayout
            banner={<ReferralBanner route='buy-nft' />}
            hasSidebar={false}
            hasExandedLogo
            isDarkThemeOverride={false}
            hasDarkmodeBtn={false}
        >
            <link rel='stylesheet' href='/css/landing.css' />
            <div
                className='landing_container'
                style={{
                    paddingBottom: 0,
                }}
            >
                <h1 style={{ fontSize: 45 }}>The FIRST Stablecoin NFT</h1>
                <h1 className='blueText'>That Pays YOU!</h1>
                <br />
                <h4 className='text-align-left'>
                    <b>
              Each NFT will be paid 1,000 XSD&apos;s when the XSD stablecoin
              minted reaches $100M.
                    </b>{' '}
            Thereafter, it is 200 XSD for each NFT for every $100M in stablecoin
            minted.
                </h4>
                <br />
                <h4 className='text-align-left'>
                    <b>Each NFT increases your Token Lockup Reward earnings:</b>{' '}
            Lockup the NFT first. Then, lockup BankX tokens in the Token Lockup Rewards for accelerated earnings. Each NFT increases your Token Lockup Rewards by 10%. Maximum of 5 can be locked up per Token Lockup Rewards.
                </h4>
                <br />
                <h4 className='text-align-left'>
                    <b>Private Telegram and Discord</b> with access to BankX information
            before it is released to the public.
                </h4>
                <br />
                <br />
                <div ref={ref}>
                    <iframe
                        width='560'
                        height='315'
                        src={`https://www.youtube.com/embed/OU3GEFfQYIE?rel=0&amp;autoplay=${playing}&mute=1`}
                        title='YouTube video player'
                        frameBorder='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        allowFullScreen
                        className='youtubeVideo'
                    />
                </div>
            </div>
            <div className='profit nft-section'>
                <div className='nft-cards'>
                    {[
                        1,
                        2,
                        3,
                        4
                    ].map((nftImageNumber, index) => {
                        return (
                            <div
                                className={`nft-card ${index % 2 !== 0 ? 'nft-card2' : ''}`}
                                key={index}
                            >
                                <img src={`/images/nft/${nftImageNumber}.jpg`} alt='Currency Creators NFT' />
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className='landing_container'>
                <h4 className='text-align-left' style={{ marginTop: 42 }}>
            Meaning Behind The NFT
                </h4>
                <p className='text-align-left'>
            We believe that currency creation should be done by and benefit the
            individual. BankX is the first stablecoin built for individual
            freedom. When you mint the XSD stablecoin, you earn interest the
            entire time it is in circulation. This is all done with complete
            autonomy. It is just you and decentralized machines (blockchain).
            The images depict the minting process and the transformation BankX
            is bringing to legacy banking. We see an unknown minter bring value
            to the system (a silver bar since the stablecoin is pegged to the
            price of 1 gram of silver). This value is transformed in to an
            unstoppable stablecoin. In the final image, the XSD stablecoin has
            an electrifying force. When people use BankX and see what it can do
            for them, it will spread everywhere.
                </p>

                <p
                    className='text-align-left'
                    style={{
                        width: '100%',
                    }}
                >
            *Please read the Currency Creators Manifesto:{' '}
                    <a
                        href='https://bankx.io/'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
              www.BankX.io
                    </a>{' '}
            or{' '}
                    <a
                        href=' https://t.me/BankXio/119512'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
               t.me/BankXio/119512
                    </a>
                </p>

                <br />
                <div className={cx('main-card', 'single-card')}>
                    <div className={cx('main-card-inner', 'lg-w')}>
                        <div className='main-card-body'>
                            <PurchaseNFTBox
                                className='nft-swap-box'
                                disableDarkTheme
                                hasSettings={false}
                                hasLandingPageRedirect
                                userAddress={userAddress}
                                referrerAddress={referrerAddress}
                            />
                        </div>
                    </div>
                </div>
                <ReferralFooter
                    userAddress={userAddress}
                    copyType='NFT'
                    route='buy-nft'
                />
                <button onClick={() => {
                    window.open('https://docs.bankx.io/', '_blank', 'noopener noreferrer');
                    return;
                }} className='button-1' style={{
                    marginTop: 20,
                    maxInlineSize: 'fit-content',
                    padding: '0px 20px'
                }}>
                    Still Have More Questions?
                </button>
                <button onClick={() => {
                    // TODO: replace with new link
                    window.open('https://t.me/BankXio', '_blank', 'noopener noreferrer');
                    return;
                }} className='button-1' style={{
                    marginTop: 20,
                    maxInlineSize: 'fit-content',
                    padding: '0px 20px'
                }}>
                    Join The Conversation
                </button>
            </div>
        </DashboardLayout>
    );
}

export default BuyNFTPage;
