import { useConnectWallet } from '@web3-onboard/react';
import { DashboardLayout } from 'components/layouts/DashboardLayout';
import ReferralBanner from 'components/ReferralBanner';
import ReferralFooter from 'components/ReferralFooter';
import { SwapBox } from 'components/SwapBox';
import { useGlobalValues } from 'context/ContractValuesProvider';
import { formatPrice } from 'helpers/formatting';
import { TokenLabels } from 'helpers/labels';
import useOnScreen from 'hooks/useOnScreen';
import useRecursiveTimeout from 'hooks/useRecursiveTimeout';
import React, {
    useCallback, useRef, useState
} from 'react';

function XSDPage() {
    const {
        silverPrice,
        xsdPrice,
        priceDifference: priceDifferenceOutput,
    } = useGlobalValues();

    const [{ wallet }, connect] = useConnectWallet();
    const userAddress = wallet?.accounts[0].address;

    // video autoplay
    const [playing, setPlaying] = useState(0);
    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(ref);
    const playVideo = useCallback(() => {
        if (isVisible && !playing) {
            setPlaying(1);
        }
    }, [
        setPlaying,
        isVisible,
        playing
    ]);
    useRecursiveTimeout(playVideo, 100);

    return (
        <DashboardLayout
            banner={<ReferralBanner connect={connect} route='silver' />}
            hasSidebar={false}
            hasExandedLogo
            isDarkThemeOverride={false}
            hasDarkmodeBtn={false}
        >
            <link rel='stylesheet' href='/css/landing.css' />
            <div className='landing_container'>
                <h1>
          Tired of the headaches and expense of managing physical silver or
          gold?
                </h1>
                <br />
                <br />
                <h2 className='blueText'>
          When you buy physical metals, you also pay broker fees, storage fees
          and insurance. And worse, it is heavy to move around so you can sell
          it.
                </h2>
                <br />
                <br />
                <h5 style={{ maxWidth: 725 }}>
          We have an easier and less expensive way for you to protect yourself
          against inflation with Silver without the cost and headaches.
                </h5>
                <br />
                <h5 style={{ maxWidth: 600 }}>
          The best part is, for a short time, we are offering our solution at a
          discount off the spot price of silver!
                </h5>
                <br />
                <br />
                <h4 className='text-align-left'>
          If you like physical metals and have an interest in cryptocurrencies,
          then you are going to love the BankX solution.
                </h4>
                <br />
                <br />
                <h4 className='text-align-left'>
          The BankX Silver Dollar (XSD) is a stablecoin that is designed to
          track the price of 1 gram of silver and backed by well-known
          cryptocurrency. This means that instead of having to store silver in a
          vault, you can redeem the XSD stablecoin for other cryptocurrency and
          sell anytime with no middlemen, broker or vault involved.
                </h4>
                <br />
                <br />
                <h3>
                    <b>
            Whenever the price of the XSD stablecoin is below the price of 1
            gram of silver, you are buying it at a discount.
                    </b>
                </h3>
                <br />
                <br />
                <div ref={ref}>
                    <iframe
                        width='560'
                        height='315'
                        src={`https://www.youtube.com/embed/8cCiKns-D_o?rel=0&autoplay=${playing}&mute=1`}
                        title='YouTube video player'
                        frameBorder='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        allowFullScreen
                        className='youtubeVideo'
                    />
                </div>
                <br />
                <br />
                <h5>It is easy to do and takes 2 steps:</h5>
                <ol>
                    <li>
            Connect your METAMASK wallet on this web page. Don’t have a
            METAMASK? Click here: <a href='https://metamask.io/'>metamask.io</a>
                    </li>
                    <li>
            Use the SWAP box below to trade Ethereum (ETH) or Binance (BNB) for
            the XSD stablecoin.
                    </li>
                </ol>
                <br />
                <CurrentPrices
                    {...{
                        silverPrice,
                        xsdPrice,
                        priceDifferenceOutput,
                        userAddress
                    }}
                />
                <SwapBox
                    disableDarkTheme={true}
                    forcedSourceToken={TokenLabels.Erc}
                    forcedTargetToken={TokenLabels.Xsd}
                    hasLandingPageRedirect
                />
                <br />
                <br />
                <h3>That is it!</h3>
                <h4>
          You now are exposed to the price of silver
                    <br />
          without all the headaches and management of physical metals.
                </h4>
                <ReferralFooter userAddress={userAddress} route='silver' />
                <button onClick={() => {
                    window.open('https://docs.bankx.io/', '_blank', 'noopener noreferrer');
                    return;
                }} className='button-1' style={{
                    marginTop: 20,
                    maxInlineSize: 'fit-content',
                    padding: '0px 20px'
                }}>
                    Still Have More Questions?
                </button>
                <button onClick={() => {
                    window.open('https://t.me/BankXio', '_blank', 'noopener noreferrer');
                    return;
                }} className='button-1' style={{
                    marginTop: 20,
                    maxInlineSize: 'fit-content',
                    padding: '0px 20px'
                }}>
                    Join The Conversation
                </button>
            </div>
        </DashboardLayout>
    );
}

type CurrentPricesProps = {
    silverPrice: string
    xsdPrice: string
    priceDifferenceOutput: number
    userAddress?: string | null,
}

export function CurrentPrices({
    userAddress,
    silverPrice,
    xsdPrice,
    priceDifferenceOutput,
}: CurrentPricesProps) {

    if (!userAddress) {
        return (
            <h4 className="blueText text-align-left">
                Connect Your Wallet To See <br/> Pricing on Each Chain.
            </h4>
        );
    }

    const discount = Math.abs(
        (priceDifferenceOutput / Number(silverPrice)) * 100
    ).toFixed(2);

    return (
        <>
            <h6 className='text-align-left'>
        The XSD stablecoin is designed to track the price of 1 gram of silver.
            </h6>
            <div className='currentPriceContainer'>
                <span className='currentPrice_label'>
          Current price of 1 gram of silver:
                </span>
                <span className='currentPrice_value'>{formatPrice(silverPrice)}</span>
            </div>
            <div className='currentPriceContainer'>
                <span className='currentPrice_label'>Current price of XSD:</span>
                <span className='currentPrice_value'>{formatPrice(xsdPrice)}</span>
            </div>
            <div className='currentPriceContainer'>
                <span className='currentPrice_label'>Discount:</span>
                <span className='currentPrice_value'>
                    {priceDifferenceOutput > 0 ? 'Sell XSD' : `${discount}%`}
                </span>
            </div>
        </>
    );
}

export default XSDPage;
