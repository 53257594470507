import { DashboardLayout } from 'components/layouts/DashboardLayout';
import { SwapBox } from 'components/SwapBox';
import TopCards from 'components/TopCards';
import { useAppSettings } from 'context/AppSettingsProvider';
import React  from 'react';

export function ExchangePage(): React.ReactElement {
    const { getThemedClass: tcx } = useAppSettings();

    return (
        <DashboardLayout>
            <div className={tcx('content-wrapper')}>
                <TopCards />
                <SwapBox />
            </div>
        </DashboardLayout>
    );
}
