import { ethers } from 'ethers';
import { Logger } from 'helpers/logging';
import { mapToBigNumber, toDecimalString } from 'helpers/number';
import { Storage } from 'helpers/storage';

import { UDPATE_DELAY } from './constants';
import { chainsCallbacksWithPriceUpdateData } from './pyth';

const ARBITRARY_REFRESH_COOLDOWN_STORAGE_KEY = '_rsk298kd81';

export async function refreshCollateralRatio(
    pidContract: ethers.Contract,
    provider: ethers.providers.Web3Provider,
    proxyContract: ethers.Contract,
    cooldownInSeconds: number,
    chain: string,
): Promise<void> {
    const signer = provider.getSigner();
    const isRouterInteraction = false;
    const receipt = await chainsCallbacksWithPriceUpdateData(
        chain,
        proxyContract,
        (priceUpdateData, updateFee) => pidContract.connect(signer).systemCalculations(priceUpdateData, { value: updateFee }),
        () => pidContract.connect(signer).systemCalculations(),
        isRouterInteraction,
    )

    Logger.log('refreshCollateralRatio: receipt', receipt);
    const currentTs = Date.now();

    Storage.set(ARBITRARY_REFRESH_COOLDOWN_STORAGE_KEY, String(currentTs + cooldownInSeconds * 1000));
    await receipt.wait(1);
}

/**
 * This function returns the millisecond value when the refresh collateral ratio contract
 * method is callable again in the UI
 */
export function getMsToRefreshAvailable(): number {
    const tsWhenReady = Storage.get(ARBITRARY_REFRESH_COOLDOWN_STORAGE_KEY) || 0;
    const msRemaining = Number(tsWhenReady) - Date.now() + UDPATE_DELAY;

    return Math.max(msRemaining, 0);
}

interface PidValues {
    bankx: string;
    collateral: string;
    xsd: string;
}
export async function getAmountsPaid(pidContract: ethers.Contract): Promise<PidValues> {
    const decimals = 18;
    const [
        xsd,
        bankx,
        collateral,
    ] = await Promise.all([
        pidContract.amountpaid1(),
        pidContract.amountpaid2(),
        pidContract.amountpaid3(),
    ]);

    const result = {
        bankx: toDecimalString(bankx, decimals),
        collateral: toDecimalString(collateral, decimals),
        xsd: toDecimalString(xsd, decimals),
    };

    Logger.log('getAmountsPaid:', result);
    return result;
}

export async function getDeficits(pidContract: ethers.Contract): Promise<PidValues> {
    const decimals = 18;
    const [
        xsd,
        bankx,
        collateral,
    ] = await Promise.all([
        pidContract.diff1(),
        pidContract.diff2(),
        pidContract.diff3(),
    ]);

    const result = {
        bankx: toDecimalString(bankx, decimals),
        collateral: toDecimalString(collateral, decimals),
        xsd: toDecimalString(xsd, decimals),
    };

    Logger.log('getDeficits:', result);
    return result;
}

export async function getCooldownDuration(pidContract: ethers.Contract): Promise<number> {
    const result = await pidContract.internal_cooldown();
    const seconds = Number(toDecimalString(result, 0));

    // default to 30 seconds
    return seconds || 30;
}

export async function getArbitrageXSDBurnableLimit(pidContract: ethers.Contract): Promise<string> {
    const xsd  = await pidContract.xsd_burnable_limit();
    const result =  toDecimalString(xsd, 18);

    Logger.log('getArbitrageXSDBurnableLimit:', result);

    return result;
}

export async function getArbitrageBurnMax(_pidContract: ethers.Contract): Promise<{
    minArbBurnBelow: string;
    maxArbBurnAbove: string;
}> {
    const [minArbBurnBelow, maxArbBurnAbove] = await Promise.all([
        // pidContract.minArbBurnBelow(),
        // pidContract.maxArbBurnAbove(),
        0,
        0,
        null,
    ]);

    const result = {
        minArbBurnBelow: toDecimalString(minArbBurnBelow, 18),
        maxArbBurnAbove: toDecimalString(maxArbBurnAbove, 18),
    };

    Logger.log('getArbitrageBurnMax', result);

    return result;
}

export function calculateArbitrageMax(
    minArbBurnBelow: string,
    maxArbBurnAbove: string,
    priceDifferential: number,
    xsdUsdPrice: string,
    bankxUsdPrice: string,
): string {
    // below the peg
    if (priceDifferential < 0) {
        Logger.log('getArbitrageBurnMax below the peg:', minArbBurnBelow);
        return minArbBurnBelow;
    }

    // above the peg
    const {
        maxArbBurnAbove: maxD18,
        xsdUsdPrice: xsdD18,
        bankxUsdPrice: bankxD18,
    } = mapToBigNumber({
        xsdUsdPrice,
        bankxUsdPrice,
        maxArbBurnAbove,
    }, 18);

    const result = maxD18.mul(xsdD18).div(bankxD18);
    Logger.log('getArbitrageBurnMax above the peg: maxArbBurnAbove', maxArbBurnAbove, 'result', result);

    return toDecimalString(result, 18);
}

interface TokenPrices {
    bankx: string;
    xsd: string;
}
export async function getTokenPrices(pidContract: ethers.Contract): Promise<TokenPrices> {
    const decimals = 6;
    const [bankx, xsd] = await Promise.all([
        pidContract.bankx_updated_price(),
        pidContract.xsd_updated_price(),
        null,
    ]);

    const result: TokenPrices = {
        bankx: toDecimalString(bankx, decimals),
        xsd: toDecimalString(xsd, decimals),
    };

    Logger.log('getTokenPrices: result', result);

    return result;
}
