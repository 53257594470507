import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { ConnectButton, ConnectButtonText } from 'components/ConnectButton';
import { FormattedValue } from 'components/FormattedValue';
import { Settings } from 'components/Settings';
import { TextInput, useTextInputContext } from 'components/TextInput';
import { TextInputName } from 'components/TextInput/types';
import { useAppSettings } from 'context/AppSettingsProvider';
import { useGlobalValues } from 'context/ContractValuesProvider';
import { useModal } from 'context/ModalContext';
import { useWeb3Context } from 'context/Web3Provider';
import {
    exchangeTokens,
    getExchangeOutput,
    getExchangePresets,
    getExchangeTokenOptions,
    getTokenOptions,
} from 'helpers/exchange';
import { useBankxActionModalConfig } from 'helpers/getPreferredBankxAction';
import { isValidInput } from 'helpers/input';
import { TokenLabels } from 'helpers/labels';
import { Logger } from 'helpers/logging';
import { abbreviated } from 'helpers/number';
import { useCustomLoadingMessage } from 'hooks/useCustomLoadingMessages';
import { useReferrals } from 'hooks/useReferrals';
import { useWallet } from 'hooks/useWallet';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import Styles from './styles.module.sass';

type SwapBoxProps = {
    disableDarkTheme?: boolean;
    forcedTargetToken?: string;
    forcedSourceToken?: string;
    hasLandingPageRedirect?: boolean;
    withLiquidityPoolInfo?: boolean;
};

export function SwapBox({
    disableDarkTheme = false,
    forcedTargetToken,
    forcedSourceToken,
    hasLandingPageRedirect = false,
    withLiquidityPoolInfo = true
}: SwapBoxProps): React.ReactElement {
    const { getThemedClass, calculateSlippageForOutput } = useAppSettings();

    function tcx(className: string): string {
        return getThemedClass(className, disableDarkTheme);
    }

    const globalValues = useGlobalValues();
    const walletValues = globalValues.walletValues;
    const { meta } = useReferrals();

    const { state: locationState } = useLocation();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const swapAction = (locationState as any)?.swapAction;
    const defaultOptions = useMemo((): ReturnType<typeof getExchangePresets> => getExchangePresets(swapAction), [swapAction]);
    const tokenOptions = useMemo((): string[] => getTokenOptions(), []);
    const [sourceToken, setSourceToken] = useState(forcedSourceToken || defaultOptions.source);
    const [targetTokenOptions, setTargetTokenOptions] = useState(getExchangeTokenOptions(sourceToken));
    const [targetToken, setTargetToken] = useState(defaultOptions.target);
    const { useInputState } = useTextInputContext();
    const [inputAmount, setInputAmount] = useInputState(TextInputName.SwapSource);
    const [outputAmount, setOutputAmount] = useInputState(TextInputName.SwapTarget);

    const inputRef = useRef(inputAmount);
    inputRef.current = inputAmount;

    const { contracts, getProvider } = useWeb3Context();
    const {
        bankxTokenContract,
        routerContract,
        xsdTokenContract,
        pidContract,
        proxyContract,
    } = contracts;
    const provider = getProvider();
    const {
        address: walletAddress,
        chain,
        priceCheckBlockDelay,
    } = useWallet();

    const poolBalance = function(): string {
        const isBankx = [sourceToken, targetToken].includes(TokenLabels.Bankx);

        if (isBankx) {
            const pool = Number(globalValues.bankxPoolBalance);
            const balance = pool - Number(globalValues.bankXTokenPrice);
            return String(balance);
        }

        const pool = Number(globalValues.xsdPoolBalance);
        const balance = pool - Number(globalValues.xsdPrice);
        return String(balance);

    }();

    const walletAmount = function (): string {
        switch (sourceToken) {
            case TokenLabels.Bankx:
                return walletValues.bankx || '-';
            case TokenLabels.Erc:
                return walletValues.balance || '-';
            case TokenLabels.Xsd:
                return walletValues.xsd || '-';
            default:
                return '-';
        }
    }();
    const hasValidInput = isValidInput(inputAmount);
    const isBalanceExceeded = Number(inputAmount) > Number(walletAmount);
    const isExchangeDisabled = !hasValidInput || isBalanceExceeded;

    const sourceTokenPrecision = sourceToken === TokenLabels.Erc ? 4 : 2;
    const targetTokenPrecision = targetToken === TokenLabels.Erc ? 4 : 2;

    /* Functions
    ------------------------------------------ */

    function updateOutput(inputAmount: string = inputRef.current, newTargetToken: string = targetToken): void {
        const output = getExchangeOutput({
            sourceToken,
            targetToken: newTargetToken,
            inputAmount,
            xsdPrice: globalValues.xsdPrice,
            bankxPrice: globalValues.bankXTokenPrice,
            ethPrice: globalValues.ethPriceInUsd,
        });

        Logger.log('OUTPUT', output);
        setOutputAmount(output);
    }

    function updateInput(outputAmount: string): void {
        const input = getExchangeOutput({
            sourceToken,
            targetToken,
            inputAmount: outputAmount,
            xsdPrice: globalValues.xsdPrice,
            bankxPrice: globalValues.bankXTokenPrice,
            ethPrice: globalValues.ethPriceInUsd,
            reverseOperation: true,
        });

        Logger.log('INPUT', input);
        setInputAmount(input);
    }

    function handleSourceTokenChange(
        e: React.ChangeEvent<HTMLSelectElement>
    ): void {
        const token = e.currentTarget.value;
        Logger.log('handleSourceTokenChange: token', token);
        setSourceToken(token);
        const targetOptions = getExchangeTokenOptions(token);
        setTargetTokenOptions(targetOptions);
        setTargetToken(targetOptions[0]);
        setInputAmount('');
        setOutputAmount('');
    }

    function handleTargetTokenChange(
        e: React.ChangeEvent<HTMLSelectElement>
    ): void {
        const token = e.currentTarget.value;
        Logger.log('handleTargetTokenChange: token', token);
        setTargetToken(token);
        updateOutput(undefined, token);
    }

    function switchInputOutput(): void {
        Logger.log('switchInputOutput: target', targetToken, 'source', sourceToken);
        setSourceToken(targetToken);
        setTargetTokenOptions(getExchangeTokenOptions(targetToken));
        setTargetToken(sourceToken);
        setInputAmount(outputAmount);
        setOutputAmount(inputAmount);
    }

    function getWalletAmount(token: string): string {
        switch (token) {
            case TokenLabels.Bankx:
                return walletValues.bankx;
            case TokenLabels.Erc:
                return walletValues.balance;
            case TokenLabels.Xsd:
                return walletValues.xsd;
            default:
                return '...';
        }
    }

    const {
        isLoading,
        message: customLoadingMessage,
        next: goToNextLoadingMessage,
        reset: resetLoadingMessages,
    } = useCustomLoadingMessage();

    async function onExchange(): Promise<void> {
        try {
            // bankx/xsd to eth has an extra approval
            const loadingMessageCount = sourceToken === TokenLabels.Erc ? 4 : 5;

            resetLoadingMessages(loadingMessageCount);

            await exchangeTokens({
                sourceToken,
                targetToken,
                inputAmount,
                expectedOutput: calculateSlippageForOutput(outputAmount),
                xsdTokenContract,
                routerContract,
                bankxTokenContract,
                pidContract,
                proxyContract,
                provider,
                walletAddress,
                chain: chain as string,
                goToNextLoadingMessage,
                shouldSendReferralCall: meta.is_referral_active,
                priceCheckBlockDelay,
            });
        } catch(e) {
            Logger.error(e);
            throw e;
        }
    }

    function handleInputChange(value: string): void {
        setInputAmount(value);
        updateOutput(value);
    }

    function handleMaxBtnClick(): void {
        if (walletValues.isLoading) {
            return;
        }

        switch (sourceToken) {
            case TokenLabels.Bankx: {
                handleInputChange(walletValues.bankx);
                break;
            }
            case TokenLabels.Erc: {
                handleInputChange(walletValues.balance);
                break;
            }
            case TokenLabels.Xsd: {
                handleInputChange(walletValues.xsd);
                break;
            }
        }
    }

    function onSourceInputChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const newInputAmount = e.currentTarget.value;
        handleInputChange(newInputAmount);
    }

    function onTargetInputChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const newInputAmount = e.currentTarget.value;
        setOutputAmount(newInputAmount);
        updateInput(newInputAmount);
    }

    function onDone() {
        resetLoadingMessages();
    }

    function onDoneWithoutErrors() {
        if (hasLandingPageRedirect) {
            window.open('https://bankx.io/', '_blank');
        }
    }

    useEffect((): void => {
        if (forcedTargetToken) {
            setTargetToken(forcedTargetToken);
        }
    }, [forcedTargetToken]);

    const bankxModalConfig = useBankxActionModalConfig(globalValues.bankxAction);

    const { triggerModal } = useModal();

    const getUserAction = useCallback(async function() {
        await triggerModal(bankxModalConfig);
    }, [bankxModalConfig, triggerModal]);

    // trigger the modal if bankx is the target token
    useEffect((): void => {
        if (targetToken === TokenLabels.Bankx) {
            getUserAction();
        }
    }, [
        getUserAction,
        targetToken,
        bankxModalConfig
    ]);

    function getUiTokenLabel(label: string): string {
        if (walletValues.isLoading) {
            return '-';
        }

        switch(label) {
            case TokenLabels.Erc:
                return walletValues.tokenLabel;
            default:
                return label;
        }
    }

    const tradeUsdValue = function (): number {
        const ercAmount = sourceToken === TokenLabels.Erc ? inputAmount : outputAmount;
        const usdValue = Number(globalValues.ethPriceInUsd) * Number(ercAmount);

        return usdValue;
    }();

    const customError = function (): string {
        if (tradeUsdValue <= 0 ) {
            return ConnectButtonText.Empty;
        }
        if (isBalanceExceeded) {
            return ConnectButtonText.NotEnoughBalance;
        }
        return ConnectButtonText.Empty;
    }();

    return (
        <div className={cx(Styles.swap, tcx('main-card'), 'single-card')}>
            <div className={cx(tcx('main-card-inner'), 'lg-w')}>
                <div className='main-card-body'>
                    <div>
                        <div className='white-form-card'>
                            <Settings
                                title='Swap'
                                isSoloCard={true}
                                isThemeToggleHidden={disableDarkTheme}
                            />
                            <div className='swap-currency-input-container'>
                                <div className='input-row'>
                                    <TextInput
                                        autoComplete='off'
                                        autoCorrect='off'
                                        className={Styles.swapInput}
                                        type='text'
                                        pattern='^[0-9]*[.,]?[0-9]*$'
                                        placeholder='0.0'
                                        minLength={1}
                                        maxLength={79}
                                        spellCheck={false}
                                        onChange={onSourceInputChange}
                                        name={TextInputName.SwapSource}
                                        value={inputAmount}
                                        disabled={isLoading}
                                    />

                                    <select
                                        onChange={handleSourceTokenChange}
                                        value={sourceToken}
                                        disabled={!!forcedSourceToken}
                                    >
                                        {tokenOptions.map((token): React.ReactElement => {
                                            return (
                                                <option key={`source-${token}`} value={token}>
                                                    {getUiTokenLabel(token)}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div
                                    className={cx(
                                        'balance-row',
                                        Styles.targetBalanceRowWithLiquidity
                                    )}
                                >
                                    <div className={Styles.liquidityInfo}>
                                        USD :{' '}
                                        <FormattedValue
                                            value={String(tradeUsdValue)}
                                            isLoading={isLoading}
                                            isCurrency
                                        />
                                    </div>
                                    <span>
                                        Balance:{' '}
                                        <FormattedValue
                                            value={getWalletAmount(sourceToken)}
                                            isLoading={isLoading}
                                            precision={sourceTokenPrecision}
                                        />
                                        <button
                                            className={Styles.maxButton}
                                            disabled={walletValues.isLoading}
                                            onClick={handleMaxBtnClick}
                                        >
                                            Max
                                        </button>
                                    </span>
                                </div>
                            </div>
                            <button
                                className={cx(tcx('drop-icon'), 'exchange')}
                                onClick={switchInputOutput}
                                disabled={!!forcedSourceToken || !!forcedTargetToken}
                                style={
                                    !!forcedSourceToken || !!forcedTargetToken
                                        ? {
                                            cursor: 'default',
                                        }
                                        : {}
                                }
                            >
                                <FontAwesomeIcon color={'#fff'} icon={faArrowDown} style={{
                                    position: 'relative',
                                    top: '-2px',
                                }}/>
                            </button>
                            <div
                                className='swap-currency-input-container'
                                style={{
                                    marginTop: 7,
                                    marginBottom: 8,
                                }}
                            >
                                <div className='input-row'>
                                    <TextInput
                                        autoComplete='off'
                                        autoCorrect='off'
                                        className={Styles.swapInput}
                                        type='text'
                                        pattern='^[0-9]*[.,]?[0-9]*$'
                                        placeholder='0.0'
                                        minLength={1}
                                        maxLength={79}
                                        spellCheck={false}
                                        onChange={onTargetInputChange}
                                        name={TextInputName.SwapTarget}
                                        value={outputAmount}
                                        disabled={isLoading}
                                    />
                                    <select
                                        value={targetToken}
                                        onChange={handleTargetTokenChange}
                                        disabled={!!forcedTargetToken}
                                    >
                                        {targetTokenOptions.map((token): React.ReactElement => {
                                            return (
                                                <option key={`target-${token}`} value={token}>
                                                    {getUiTokenLabel(token)}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div
                                    className={cx(
                                        'balance-row',
                                        Styles.targetBalanceRowWithLiquidity
                                    )}
                                >
                                    <div className={Styles.liquidityInfo}>
                                        {withLiquidityPoolInfo && (
                                            <>
                                                Liquidity Pool:{' '}
                                                <FormattedValue
                                                    value={`$${abbreviated(poolBalance)}`}
                                                    isLoading={isLoading}
                                                    isRaw
                                                />
                                            </>
                                        )}
                                    </div>
                                    <span>
                                        Balance:{' '}
                                        <FormattedValue
                                            value={getWalletAmount(targetToken)}
                                            isLoading={isLoading}
                                            precision={targetTokenPrecision}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='btn-wrap'>
                            <ConnectButton
                                className={tcx('button-1')}
                                disabled={isExchangeDisabled || isLoading}
                                onClick={onExchange}
                                onDone={onDone}
                                onDoneWithoutErrors={onDoneWithoutErrors}
                                customLoadingMessage={customLoadingMessage}
                                isLimitExceeded={customError.length > 0}
                                limitMessage={customError}
                            >
                                Swap
                            </ConnectButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
