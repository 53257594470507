import { differenceInDays } from 'date-fns';
import { ethers } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import { Logger } from 'helpers/logging';
import { CustomLoadingMessage } from 'hooks/useCustomLoadingMessages';

import { formatBigNumberDate } from './date';
import { isProd } from './env';
import { isValidInput } from './input';
import {
    mapToBigNumber,
    toBigIntString,
    toBigNumber,
    toDecimalString,
    truncateDecimalValue,
} from './number';
import { systemCalculations } from './pool';
import { getSilverPriceFromPyth, getSilverPricePerGram } from './price';
import { getDeadline } from './deadline';
import { priceUpdateCallback } from './pyth';

export async function getMintingInterestRate (xsdContract: ethers.Contract) {
    const mintingInterestRate = await xsdContract.interest_rate();
    const formatted = toDecimalString(mintingInterestRate, 6);

    Logger.log('mintingInterestRate:', mintingInterestRate, formatted);
    return formatted;
}

export interface MintInfo {
    accumulatedInterest: string;
    interestRate: string;
    silverPrice: number;
    timeLastMintedFormatted: string;
    totalAccumulatedInterest: string;
    totalMinted: string;
}
export async function getMintInformation (
    walletAddress: string | null,
    collateralContract: ethers.Contract,
    xsdContract: ethers.Contract,
    chain: string,
): Promise<MintInfo> {
    /*
        uint256 accum_interest; //accumulated interest from previous mints
        uint256 interest_rate; //interest rate at that particular timestamp
        uint256 time; //last timestamp
        uint256 amount; //XSD amount minted
        uint256 perXSD; //interest redeemed per XSD redeemed
     */
    const [
        mintInformation,
        accruingInterestRateRaw,
    ] = await Promise.all([
        walletAddress ? collateralContract.mintMapping(walletAddress) : null,
        walletAddress ? xsdContract.interest_rate() : null,
    ]);

    const isRouterInteraction = false;
    const silverPrice = await priceUpdateCallback(
        chain,
        () => getSilverPriceFromPyth(),
        () => getSilverPricePerGram(xsdContract),
        isRouterInteraction,
    )

    Logger.warn('silver price is', silverPrice)

    // we always need the silver price
    if (!walletAddress) {
        return {
            totalAccumulatedInterest: '',
            accumulatedInterest: '',
            interestRate: '',
            silverPrice,
            timeLastMintedFormatted: '',
            totalMinted: '',
        };
    }

    const decimals = 18;
    const totalMintedRaw = toDecimalString(mintInformation.amount, decimals);

    const accumulatedInterest = toDecimalString(mintInformation.accum_interest, decimals);
    const interestRate = toDecimalString(mintInformation.interest_rate, 4);
    const timeLastMintedFormatted = formatBigNumberDate(mintInformation.time);
    const totalMinted = truncateDecimalValue(totalMintedRaw, decimals);
    const lastMintEpoch = Number(toDecimalString(mintInformation.time, 0));
    const daysSinceLastMint = Math.max(differenceInDays(new Date(), new Date(lastMintEpoch * 1000)), 0);
    const accruingInterestRate = toDecimalString(accruingInterestRateRaw, 6);

    // (date of last mint  / 365) * XSD in USD Value * weighted interest rate
    const accruingInterest = Number(accruingInterestRate) * (daysSinceLastMint / 365) * Number(silverPrice) * Number(totalMinted);
    const totalAccumulatedInterestRaw = Number(accumulatedInterest) + accruingInterest;
    const totalAccumulatedInterest = totalAccumulatedInterestRaw < .005 ? String(0) : String(totalAccumulatedInterestRaw);

    const itemsToLog = {
        lastMintEpoch,
        accruingInterestRate,
        accumulatedInterest,
        accruingInterest,
        totalAccumulatedInterest,
        interestRate,
        daysSinceLastMint,
        totalMinted,
        timeLastMintedFormatted,
        silverPrice,
    };

    Logger.log('mintInformation:', itemsToLog);

    return {
        totalAccumulatedInterest,
        accumulatedInterest,
        interestRate,
        silverPrice,
        timeLastMintedFormatted,
        totalMinted,
    };
}

export async function mintOneToOne (
    ethRequired: number | string,
    expectedXsd: string,
    collateralPoolContract: ethers.Contract,
    provider: ethers.providers.Web3Provider,
    goToNextLoadingMessage: (message?: string) => void,
): Promise<void> {
    const signer = provider.getSigner();
    const eth = toBigIntString(ethRequired, 18);
    const xsd = toBigIntString(expectedXsd, 18);
    const deadline = await getDeadline(provider)

    Logger.log('mintOneToOne wethRequired:', ethRequired, eth);
    Logger.log('mintOneToOne expectedXsd:', expectedXsd, xsd);

    goToNextLoadingMessage();
    const collateralReceipt = await collateralPoolContract.connect(signer).mint1t1XSD(xsd, deadline, { value: eth });
    Logger.log('mintOneToOne collateralReceipt', collateralReceipt);

    goToNextLoadingMessage(CustomLoadingMessage.Mint);
    await collateralReceipt.wait(1);
}

export async function mintAlgorithmicXSD (
    requiredBankx: number | string,
    expectedXsd: number | string,
    collateralPoolContract: ethers.Contract,
    bankxContract: ethers.Contract,
    provider: ethers.providers.Web3Provider,
    pidContract: ethers.Contract,
    goToNextLoadingMessage: (message?: string) => void,
    chain: string,
    proxyContract: ethers.Contract,
): Promise<void> {
    const signer = provider.getSigner();
    const bankx = toBigIntString(requiredBankx, 18);
    const xsd = toBigIntString(expectedXsd, 18);
    const deadline = await getDeadline(provider);

    Logger.log('mintAlgorithmicXSD requiredBankx:', requiredBankx, bankx);
    Logger.log('mintAlgorithmicXSD expectedXsd:', expectedXsd, xsd);

    // step 1: price check
    goToNextLoadingMessage(CustomLoadingMessage.SystemsCalculations);
    await systemCalculations(pidContract, proxyContract, signer, chain);

    // step 2: bankx approval
    goToNextLoadingMessage();
    const bankxReceipt = await bankxContract.connect(signer).approve(collateralPoolContract.address, bankx);
    Logger.log('mintAlgorithmicXSD bankxReceipt', bankxReceipt);
    await bankxReceipt.wait(1);

    // step 3: sending collateral
    goToNextLoadingMessage(CustomLoadingMessage.SendingCollateral);
    const collateralReceipt = await collateralPoolContract.connect(signer).mintAlgorithmicXSD(bankx, xsd, deadline);
    Logger.log('mintAlgorithmicXSD collateralReceipt', collateralReceipt);

    // step 4: waiting
    goToNextLoadingMessage(CustomLoadingMessage.Mint);
    await collateralReceipt.wait(1);
}

export async function mintFractionalXSD (
    requiredWeth: number | string,
    requiredBankx: number | string,
    expectedXsd: number | string,
    collateralPoolContract: ethers.Contract,
    bankxContract: ethers.Contract,
    pidContract: ethers.Contract,
    provider: ethers.providers.Web3Provider,
    goToNextLoadingMessage: (message?: string) => void,
    chain: string,
    proxyContract: ethers.Contract,
): Promise<void> {
    const signer = provider.getSigner();
    const eth = toBigIntString(requiredWeth, 18);
    const bankx = toBigIntString(requiredBankx, 18);
    const xsd = toBigIntString(expectedXsd, 18);
    const deadline = await getDeadline(provider);

    Logger.log('mintFractionalXSD requiredEth:', requiredWeth, Number(eth));
    Logger.log('mintFractionalXSD requiredBankx:', requiredBankx, Number(bankx));
    Logger.log('mintFractionalXSD expectedXsd:', expectedXsd, Number(xsd));

    // step 1: price check
    goToNextLoadingMessage(CustomLoadingMessage.SystemsCalculations);
    await systemCalculations(pidContract, proxyContract, signer, chain);

    // step 2: approving bankx
    goToNextLoadingMessage();
    const bankxReceipt = await bankxContract.connect(signer).approve(collateralPoolContract.address, bankx);
    Logger.log('mintFractionalXSD bankxReceipt', bankxReceipt);
    await bankxReceipt.wait(1);

    // step 3: mint fractional
    goToNextLoadingMessage(CustomLoadingMessage.SendingCollateral);
    const collateralReceipt = await collateralPoolContract.connect(signer).mintFractionalXSD(bankx, xsd, deadline, { value: eth });
    Logger.log('mintFractionalXSD collateralReceipt', collateralReceipt);

    // step 4: waiting
    goToNextLoadingMessage(CustomLoadingMessage.Mint);
    await collateralReceipt.wait(1);
}

interface MintRequirements {
    bankx: string;
    eth: string;
}

interface MintRequirementsConfig {
    bankxTokenPrice: string;
    collateralPercentage: string;
    ethPriceInUsd: string;
    silverPrice: string;
    xsdToMint: string;
}

export function calculateMintRequirements(config: MintRequirementsConfig): MintRequirements {
    try {
        if (!config.xsdToMint || !isValidInput(config.xsdToMint)) {
            throw Error('invalid input');
        }

        Logger.log('calculateMintRequirements: collateralPercentage', config.collateralPercentage);

        const decimals = 18;

        const {
            xsdToMint,
            bankxTokenPrice,
            ethPriceInUsd,
            collateralPercentage,
            silverPrice,
        } = mapToBigNumber(config, decimals);

        const one = toBigNumber('1', decimals);
        const requiredUsd = xsdToMint.mul(silverPrice);
        const requiredWeth =  requiredUsd.div(ethPriceInUsd).mul(collateralPercentage).div(one).or('0');
        const bankxRatio = one.sub(collateralPercentage);
        const requiredBankX = requiredUsd.div(bankxTokenPrice).mul(bankxRatio).div(one).or('0');

        const result = {
            bankx: formatUnits(requiredBankX, decimals),
            eth: formatUnits(requiredWeth, decimals),
        };

        Logger.log('calculateMintRequirements: result', result);

        return result;

    } catch (e) {
        Logger.log('calculateMintRequirements: error', e);

        return {
            bankx: '',
            eth: '',
        };
    }
}

export function getMaxXSDMintAmount(config: Omit<MintRequirementsConfig, 'xsdToMint'> & { eth: string; bankx: string;}): string {
    const decimals = 18;

    /**
     * calculate requirements to mint
     * eth = xsdToMint * silverPrice / ethPrice * collateralPercentage
     * bankx = xsdToMint * silverPrice / bankxPrice * collateralPercentage
     *
     * the reverse - calculate max xsd given user bankx and eth
     * xsd = eth / collateralPercentage * ethPrice / silverPrice
     * xsd = bankx / collateralPercentage * bankxPrice / silverPrice
     */
    const {
        bankx,
        bankxTokenPrice,
        collateralPercentage,
        eth,
        ethPriceInUsd,
        silverPrice,
        zero,
        one,
    } = mapToBigNumber({
        ...config,
        zero: '0',
        one: '1',
    }, decimals);

    const bankxRatio = one.sub(collateralPercentage);

    Logger.log( 'getMaxXSDMintAmount: bankxRatio', bankxRatio);
    Logger.log( 'getMaxXSDMintAmount: config', config);

    let maxXsdWithEth = zero;
    let maxXsdWithBankx = zero;

    if (Number(config.collateralPercentage) > 0) {
        maxXsdWithEth =  eth.mul(ethPriceInUsd).mul(one).div(collateralPercentage).div(silverPrice);
    }

    if (Number(config.collateralPercentage) < 1) {
        maxXsdWithBankx =  bankx.mul(bankxTokenPrice).mul(one).div(bankxRatio).div(silverPrice);
    }

    const max = {
        bankx: formatUnits(maxXsdWithBankx, decimals),
        eth: formatUnits(maxXsdWithEth, decimals),
    };

    Logger.log( 'getMaxXSDMintAmount: max', max);

    switch(Number(config.collateralPercentage)) {
        case 1:
            return max.eth;
        case 0:
            return max.bankx;
        default:
            return Number(max.bankx) > Number(max.eth) ? max.eth : max.bankx;
    }
}

export function checkIfMintIsDisabled(silverPrice: number, xsdPrice: string): boolean {
    // https://ossllc.atlassian.net/browse/BAN-266
    // please grey this out if the price of XSD is more than 2 cents BELOW the price of 1 gram of silver.
    const isDisabled = (silverPrice - Number(xsdPrice)) > 0.02;
    const isProduction = isProd();
    Logger.log('checkIfMintIsDisabled:', {
        silverPrice,
        xsdPrice,
        isDisabled,
        isProduction
    });

    // only disable in production
    return isDisabled && isProduction;

}
