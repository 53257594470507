import cx from 'classnames';
import { CopyIcon, CopyText } from 'components/CopyText';
import { useGetReferralLink } from 'hooks/useReferrals';
import React from 'react';

import Styles from './styles.module.scss';

export default function ReferralBox(): React.ReactElement | null {
    const { link, getText } = useGetReferralLink();

    if (!link) {
        return null;
    }

    return (
        <div className={cx(Styles.box, 'side-info-box')}>
            <span>Your referral link:</span>
            <CopyText text={link}>
                {getText(12, 4)} <CopyIcon/>
            </CopyText>
        </div>
    );
}
