import { useWeb3Context } from 'context/Web3Provider';
import { getIsArbitragePaused } from 'helpers/arbitrage';
import { getBankxInflation } from 'helpers/bankx';
import { getCertificateOfDepositInfo } from 'helpers/certificateOfDeposit';
import { Logger } from 'helpers/logging';
import {
    getMintInformation,
    getMintingInterestRate,
    MintInfo as IMintInfo,
} from 'helpers/minting';
import type {  TNFTInfo } from 'helpers/nft';
import { getNFTInfo } from 'helpers/nft';
import {
    getAmountsPaid,
    getArbitrageBurnMax,
    getArbitrageXSDBurnableLimit,
    getCooldownDuration,
    getDeficits,
    getTokenPrices,
} from 'helpers/pid';
import { getAvailableExcessCollatDV, getCollateralBackedXSDInCirculation } from 'helpers/pool';
import {
    getBankxCollateralBalance,
    getCollateralPoolBalance,
    getXSDCollateralBalance,
    getXSDTotalSupply,
} from 'helpers/pool';
import {
    getBankXTokenPrice,
    getCollateralPercentage,
    getEthPriceInUsd,
    getXSDPrice,
} from 'helpers/price';
import { useWallet } from 'hooks/useWallet';
import { reduce } from 'lodash';
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import {
    GlobalsWithWalletValues, GlobalValuesContext, GlobalValuesProvider
} from './GlobalValuesProvider';

export type { MintInfo } from 'helpers/minting';

interface ContractValuesContext {
    getAmountsPaid(): ReturnType<typeof getAmountsPaid>;
    getBankXPrice(): Promise<string>;
    getCollateralPercentage(): Promise<string>;
    getDeficits(): ReturnType<typeof getDeficits>;
    getETHPrice(): Promise<string>;
    getMintInfo(): Promise<IMintInfo>;
    getXSDPrice(): Promise<string>;
    getXSDTotalSupply(): Promise<string>;
    getPIDRefreshCooldownDuration(): Promise<number>;
    getMintingInterestRate(): Promise<string>;
    resetResults(): number;
    getBankxCollateralBalance(): Promise<string>;
    getXSDCollateralBalance(): Promise<string>;
    getCollateralPoolBalance(): Promise<string>;
    getCertificateOfDepositInfo(): Promise<{
        LPB: string;
        LPBBonusPercent: string;
        currentDay: string;
    }> | null;
    getBankxInflation(): Promise<string>;
    getCollateralBackedXSDInCirculation(): Promise<string>;
    getAvailableExcessCollatDV(): Promise<string>;
    getNFTInfo(): Promise<TNFTInfo>;
    getArbitrageXSDBurnableLimit(): ReturnType<typeof getArbitrageXSDBurnableLimit>;
    getIsArbitragePaused(): Promise<boolean>;
    getArbitrageBurnMax(): ReturnType<typeof getArbitrageBurnMax>;
    getTokenPrices(): ReturnType<typeof getTokenPrices>;
}

const Context = createContext<ContractValuesContext>(
    {} as unknown as ContractValuesContext
);

/**
 * This provider stores values from contracts that are used across multiple pages.
 * There is a reset after 60 seconds to update stale values.
 */
export function ContractValuesProvider(
    props: React.PropsWithChildren
): React.ReactElement {
    const { contracts, logContracts } = useWeb3Context();
    const { address: walletAddress, chain } = useWallet();

    const resultsRef = useRef<Record<string, unknown>>({});
    const [resetTs, setResetTs] = useState<number | null>(null);

    const fetchFromRef = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async (key: string, getData: () => Promise<any>): Promise<any> => {
            const stored = resultsRef.current?.[key];
            if (stored) {
                return stored;
            }

            const result = await getData();
            resultsRef.current[key] = result;

            // reset in order to refresh stale values
            setTimeout((): void => {
                resultsRef.current[key] = null;
            }, 60000);
            return result;
        },
        []
    );

    const methods = useMemo((): ContractValuesContext => {
        const {
            arbitrageContract,
            bankxTokenContract,
            bankxNftContract,
            bankxPoolContract,
            cdContract,
            collateralPoolContract,
            pidContract,
            xsdTokenContract,
            xsdPoolContract,
        } = contracts;

        const helpers: Omit<ContractValuesContext, 'resetResults'> = {
            getAmountsPaid: () => getAmountsPaid(pidContract),
            getBankXPrice: () => getBankXTokenPrice(pidContract),
            getCollateralPercentage: () => getCollateralPercentage(xsdTokenContract),
            getDeficits: () => getDeficits(pidContract),
            getETHPrice: () => getEthPriceInUsd(xsdTokenContract, chain),
            getMintInfo: () => getMintInformation(
                walletAddress,
                collateralPoolContract,
                xsdTokenContract,
                chain as string,
            ),
            getMintingInterestRate: () => getMintingInterestRate(xsdTokenContract),
            getPIDRefreshCooldownDuration: () => getCooldownDuration(pidContract),
            getXSDPrice: () => getXSDPrice(pidContract),
            getXSDTotalSupply: () => getXSDTotalSupply(xsdTokenContract),
            getBankxCollateralBalance: () => getBankxCollateralBalance(bankxPoolContract),
            getXSDCollateralBalance: () => getXSDCollateralBalance(xsdPoolContract),
            getCollateralPoolBalance: () => getCollateralPoolBalance(collateralPoolContract),
            getBankxInflation: () => getBankxInflation(bankxTokenContract),
            getCollateralBackedXSDInCirculation: () => getCollateralBackedXSDInCirculation(collateralPoolContract),
            getAvailableExcessCollatDV: () => getAvailableExcessCollatDV(collateralPoolContract),
            getArbitrageXSDBurnableLimit: () => getArbitrageXSDBurnableLimit(pidContract),
            getIsArbitragePaused: () => getIsArbitragePaused(arbitrageContract),
            getArbitrageBurnMax: () => getArbitrageBurnMax(pidContract),
            getTokenPrices: () => getTokenPrices(pidContract),

            // TODO: these functions rely on cd or nft contract which is not available to
            // polygon at this time
            getNFTInfo: () => getNFTInfo(bankxNftContract),
            getCertificateOfDepositInfo: () => getCertificateOfDepositInfo(cdContract),
        };

        function resetResults(): number {
            const ts = Date.now();
            resultsRef.current = {};
            setResetTs(ts);
            Logger.log('resetResults invoked');
            return ts - (resetTs || 0);
        }

        logContracts('ContractValuesProvider');

        const result = reduce(
            helpers,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (memo: any, helper: any, name): Partial<ContractValuesContext> => {
                return {
                    ...memo,
                    [name]: () => fetchFromRef(name, helper),
                };
            },
            { resetResults }
        );

        return result as ContractValuesContext;
    }, [
        fetchFromRef,
        resetTs,
        setResetTs,
        walletAddress,
        contracts,
        chain,
        logContracts,
    ]);

    useEffect((): void => {
        // reset the stored values if chain changes
        resultsRef.current = {};
    }, [chain]);

    return (
        <Context.Provider value={methods}>
            <GlobalValuesProvider>
                {props.children}
            </GlobalValuesProvider>
        </Context.Provider>
    );
}

export function useContractValues(): ContractValuesContext {
    return useContext(Context);
}

export function useGlobalValues(): GlobalsWithWalletValues {
    return useContext(GlobalValuesContext);
}
