import cx from 'classnames';
import { useAppSettings } from 'context/AppSettingsProvider';
import { useGlobalValues } from 'context/ContractValuesProvider';
import { Contract } from 'helpers/addresses';
import { Action, getPreferredBankxAction } from 'helpers/getPreferredBankxAction';
import { CollateralStatus } from 'helpers/pool';
import { useWallet } from 'hooks/useWallet';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FormattedValue } from './FormattedValue';

export default function TopCards() {
    const { getThemedClass: tcx } = useAppSettings();
    const { isConnected } = useWallet();
    const navigate = useNavigate();

    const {
        bankxInflation,
        collateralPercentage,
        collateralInfo,
        priceDifference: priceDifferenceOutput,
        silverPrice,
        xsdPrice,
        certificateOfDepositInfo,
        collateralAction,
        bankxPoolLiquidity,
        xsdPoolLiquidity,
        topCards,
    } = useGlobalValues();

    const cdPercentage = certificateOfDepositInfo?.LPBBonusPercent;

    const rewards = function(): { label: string, onNavigate: () => void } {
        let label = '';
        let navigateTo = '';
        let state: Partial<{targetPool: string}> = {};

        const bankxAction = getPreferredBankxAction(
            xsdPoolLiquidity.maxContribution,
            bankxPoolLiquidity.maxContribution,
            collateralInfo.maxContribution,
        );

        if (bankxAction.action === Action.XsdLiquidity) {
            label = 'Liquidity Rewards - XSD Pool';
            navigateTo = bankxAction.redirectPath;
            state = bankxAction.redirectOptions.state;

        } else if (bankxAction.action === Action.BankxLiquidity) {
            label = 'Liquidity Rewards - BankX Pool';
            navigateTo = bankxAction.redirectPath;
            state = bankxAction.redirectOptions.state;

        } else if (bankxAction.action === Action.CollateralDeficit) {
            label = 'Add Collateral To Collateral Pool';
            navigateTo = bankxAction.redirectPath;

        } else if (Number(cdPercentage) > 20) {
            label = 'Token Lockup Rewards';
            navigateTo = '/certificate';
        } else if (isConnected) {
            label = 'Mint XSD';
            navigateTo = '/mint';
        }

        // eslint-disable-next-line
        { // TODO: remove when ready; see https://ossllc.atlassian.net/browse/BAN-412
            label = 'Buy XSD';
            navigateTo = '/liquidity-pool';
            state.targetPool = Contract.XSDPool;
        }

        function onNavigate(): void {
            if (navigateTo) {
                navigate(navigateTo, { state });
            }
        }

        return {
            label,
            onNavigate,
        };
    }();

    const swapAction = priceDifferenceOutput > 0 ? 'SELL' : 'BUY';

    return (
        <div className="card-wrapper">
            <button
                className={cx(tcx('w-card'), 'black-blue cursor-pointer active')}
                onClick={rewards.onNavigate}
            >
                <div>
                    <span className="top-card__label">Earn Most Rewards:</span>
                    <br />
                    <FormattedValue value={rewards.label} isRaw={true} />
                </div>
            </button>

            <button
                className={cx(tcx('w-card'), 'dark-blue cursor-pointer', { active: collateralAction !== CollateralStatus.FullyBacked })}
                onClick={() => navigate('/collateral')}
            >
                <div>
                    <span className="top-card__label">Collateral Ratio: </span>
                    <FormattedValue value={collateralPercentage} isPercentage={true} showDashOnEmptyString={true}/>
                </div>
                <div className="mt-2">
                    <span className="top-card__label">Collateral Pool: </span>
                    <FormattedValue value={collateralAction} isRaw={true}/>
                </div>
            </button>

            <button
                className={cx(tcx('w-card'), 'medium-blue cursor-pointer', { active: priceDifferenceOutput !== 0 })}
                onClick={() => navigate('/swap', { state: { swapAction } })}
            >
                <div>
                    <span className="top-card__label">XSD: </span>
                    <FormattedValue value={xsdPrice} showDashOnEmptyString={true}/>
                    <span className="top-card__label ml-2">Silver: </span>
                    <FormattedValue value={silverPrice} showDashOnEmptyString={true}/>
                </div>
                <div className="mt-2">
                    <span className="top-card__label">{topCards.differential.label} </span>
                    <FormattedValue
                        value={topCards.differential.value}
                        isRaw={true}
                    />
                </div>
            </button>

            <button
                className={cx(tcx('w-card'), 'light-blue cursor-pointer', { active: Number(cdPercentage) > 20 })}
                onClick={() => navigate('/certificate')}
            >
                <div>
                    <span className="top-card__label">BankX Token: </span>
                    <FormattedValue value={bankxInflation} isRaw={true}/>
                </div>
                <div className="mt-2">
                    <span className="top-card__label">Longer Pays Better: </span>
                    <FormattedValue
                        value={String(Number(cdPercentage) / 100)}
                        isPercentage={true}
                        showDashOnEmptyString={true}
                    />
                </div>
            </button>
        </div>
    );
}
