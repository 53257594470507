import { useAppSettings } from 'context/AppSettingsProvider';
import { ethers } from 'ethers';
import {
    getCDFinancials,
    getStakeStartBonusXs
} from 'helpers/certificateOfDeposit';
import { daysToDate } from 'helpers/date';
import { formatCommas } from 'helpers/formatting';
import React from 'react';

type FinancialListProps = {
    provider: ethers.providers.Web3Provider,
    cdContract: ethers.Contract,
    bankxAmountToStake: number
    stakeEndDate: Date
    longerPaysBetterBonusPercent: string
    longerPaysBetter: string
    nftCount: number
    nftsInLimbo?: number
}

function FinancialList({
    cdContract,
    provider,
    bankxAmountToStake,
    stakeEndDate,
    longerPaysBetterBonusPercent,
    longerPaysBetter,
    nftCount,
}: FinancialListProps) {
    const { getThemedClass: tcx } = useAppSettings();

    const [stakeStartBonusXs, setStakeStartBonusXs] = React.useState<number | null>(null);

    React.useEffect(() => {
        async function fetchStakeStartBonusXs() {
            const bonusXs = await getStakeStartBonusXs({
                provider,
                cdContract,
                newStakedXs: bankxAmountToStake,
                newStakedDays: daysToDate(stakeEndDate),
                nftCount
            });
            setStakeStartBonusXs(bonusXs);
        }

        fetchStakeStartBonusXs();
    }, [
        bankxAmountToStake,
        stakeEndDate,
        nftCount,
        provider,
        cdContract
    ]);

    const daysStaked = daysToDate(stakeEndDate);
    const {
        lpb, bpb, totalNFTBonus, totalRewards, bankXAtMaturity
    } =
    getCDFinancials({
        bankX: bankxAmountToStake,
        daysStaked: daysStaked,
        longerPaysBetterBonusPercent: Number(longerPaysBetterBonusPercent),
        longerPaysBetter: Number(longerPaysBetter),
        nftCount,
    });

    const totalRewardsToDisplay = formatCommas(stakeStartBonusXs || totalRewards);
    const bankXAtMaturityToDisplay = formatCommas(stakeStartBonusXs ? Number(stakeStartBonusXs) + bankxAmountToStake : bankXAtMaturity);

    return (
        <>
            <li
                title='20% boost per year.'
                style={{
                    cursor: 'help',
                }}
            >
                <b>Longer Pays Better:</b>
                <span>{formatCommas(lpb)}</span>
            </li>
            <li
                title='.0667% - 10% boost the bigger you lockup.'
                style={{
                    cursor: 'help',
                }}
            >
                <b>Bigger Pays Better:</b>
                <span>{formatCommas(bpb)}</span>
            </li>
            <li
                title='Boost Longer and Bigger Pays Better by 10% per NFT locked.  Max of 5 per CD.'
                style={{
                    cursor: 'help',
                }}
            >
                <b>Locked NFT Bonus:</b>
                <span>
                    {!totalNFTBonus ? (
                        <a href='/#/nft'>Click &apos;NFT&apos; for more rewards</a>
                    ) : (
                        formatCommas(totalNFTBonus)
                    )}
                </span>
            </li>
            <li
                style={{
                    cursor: 'help',
                }}
            >
                <b>Total Rewards:</b>
                <span>{totalRewardsToDisplay}</span>
            </li>
            <li
                style={{
                    cursor: 'help',
                }}
            >
                <b>BankX at Maturity:</b>
                <span>{bankXAtMaturityToDisplay}</span>
            </li>
            <p className={tcx('doesNotIncludeText')}>
          *Does not include your share of the 5.28% inflation per year.
            </p>

            <div
                style={{
                    background: '#e85f5f',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    padding: 10,
                    textAlign: 'center',
                    marginBottom: 20,
                }}
            >
                <p
                    style={{
                        margin: 0,
                        fontSize: 10,
                    }}
                >
                    This is only an estimation. Your rewards may vary depending on the number of people that lockup tokens who share in the inflation which changes daily.
                </p>
            </div>
        </>
    );
}

export default FinancialList;
