import { useAppSettings } from 'context/AppSettingsProvider';
import { useGlobalValues } from 'context/ContractValuesProvider';
import { toCurrency } from 'helpers/number';
import { getThemedClassName } from 'helpers/theme';
import React from 'react';

export default function SideInfo() {
    const { bankXTokenPrice, tvlValue } = useGlobalValues();
    const { isDarkTheme } = useAppSettings();

    return (
        <div className="side-info-wrapper">
            <div
                className={getThemedClassName('side-info-box', isDarkTheme)}
                style={{
                    marginBottom: '10px',
                }}
            >
                <ul>
                    <li>
                        <label>BankX Token</label>
                        <span>{toCurrency(bankXTokenPrice, 5)}</span>
                    </li>
                    <li>
                        <label>XSD TVL</label>
                        <span>{toCurrency(tvlValue)}</span>
                    </li>
                </ul>
            </div>
        </div>
    );
}
