import { AddressMap } from './types';

export const BinanceSmartChainAddresses: AddressMap = {
    BankXToken:           '0x4d369BD339709021267E92702A9E4cE30be85706',
    XSDToken:             '0x39400E67820c88A9D67F4F9c1fbf86f3D688e9F6',
    BankXPool:            '0xfa0870077A65dBFde9052ad16B04C3e1A885CE2d',
    XSDPool:              '0x8A4e0e2A778dF8cE4EA5D5108FFfE690CC9Ae07a',
    CollateralPool:       '0xFbfEd964243331483970Cb20BDD95b4d5Acf88cB',
    PIDController:        '0x9Bca6740c6283b97aa2030715C88932D6D1e215E',
    RewardManager:        '0xc13f4DfAA06481b323629c73D715be317DAc6677',
    RouterAddress:        '0xaaDAE9117dF8b5d584378a41a105CC4862A16E99',
    Arbitrage:            '0x79471BA0cCA010D8623f896C593d546691E53e5e',
    CertificateOfDeposit: '0xED024d771261D765B9Dc7b0947ef271ea006248F',
    BankXNFT:             '0x11214D41a85306f725cdd9A318993b122bAa6DFe',
    NFTBonus:             '0x369B83fe557Ef815572A340F5275D8D764615D15',
};

// 10/04/2024 1524PDT
// BankXToken address:      0x4d369BD339709021267E92702A9E4cE30be85706
// XSDToken address:        0x39400E67820c88A9D67F4F9c1fbf86f3D688e9F6
// BankX Pool address:      0xfa0870077A65dBFde9052ad16B04C3e1A885CE2d
// XSD Pool address:        0x8A4e0e2A778dF8cE4EA5D5108FFfE690CC9Ae07a
// Collateral Pool address: 0xFbfEd964243331483970Cb20BDD95b4d5Acf88cB
// PID controller address:  0x9Bca6740c6283b97aa2030715C88932D6D1e215E
// Reward Manager:          0xc13f4DfAA06481b323629c73D715be317DAc6677
// Router address:          0xaaDAE9117dF8b5d584378a41a105CC4862A16E99
// Arbitrage:               0x79471BA0cCA010D8623f896C593d546691E53e5e
// CD address:              0xED024d771261D765B9Dc7b0947ef271ea006248F
// BankXNFT address:        0x11214D41a85306f725cdd9A318993b122bAa6DFe
// NFTBonus address:        0x369B83fe557Ef815572A340F5275D8D764615D15

// 8/26/2024 1944 PDT
// BankXToken address:     0x4d369BD339709021267E92702A9E4cE30be85706
// XSDToken address:       0x39400E67820c88A9D67F4F9c1fbf86f3D688e9F6
// BankX Pool address:     0xfa0870077A65dBFde9052ad16B04C3e1A885CE2d
// XSD Pool address:       0x8A4e0e2A778dF8cE4EA5D5108FFfE690CC9Ae07a
// CollateralPool address: 0xd138CAe6088A41CEF5acd8b332ACea349Cd91d82
// PID controller address: 0x82B403e35D393174f33Ac8c72147D2BB3dC5Cc06
// Reward Manager:         0xc13f4DfAA06481b323629c73D715be317DAc6677
// Router address:         0xaaDAE9117dF8b5d584378a41a105CC4862A16E99
// Arbitrage:              0x79471BA0cCA010D8623f896C593d546691E53e5e
// CD address:             0xED024d771261D765B9Dc7b0947ef271ea006248F
// BankXNFT address:       0x11214D41a85306f725cdd9A318993b122bAa6DFe
// NFTBonus address:       0x369B83fe557Ef815572A340F5275D8D764615D15

// 07/12/2024 1317PDT
// BankXToken address:     0x4d369BD339709021267E92702A9E4cE30be85706
// XSDToken address:       0x39400E67820c88A9D67F4F9c1fbf86f3D688e9F6
// BankXPool address:      0xfa0870077A65dBFde9052ad16B04C3e1A885CE2d
// XSDPool address:        0x8A4e0e2A778dF8cE4EA5D5108FFfE690CC9Ae07a
// CollateralPool address: 0x55A75059065296a5a8cdfadCF6945ed2cC5B8eC6
// PID controller address: 0x6683FFdA5267e1F7E9Bf6790849a4eCdD63ed134
// Reward Manager:         0x2147F5c02c2869E8C2d8F86471d3d7500355d698
// Router address:         0x0626A71D29f85c0fC665612623991Aa2EA2EAB62
// Arbitrage:              0x79471BA0cCA010D8623f896C593d546691E53e5e
// CD address:             0xED024d771261D765B9Dc7b0947ef271ea006248F
// BankXNFT address:       0x11214D41a85306f725cdd9A318993b122bAa6DFe
// NFTBonus address:       0x369B83fe557Ef815572A340F5275D8D764615D15

// 02/18/2024 2020PST
// BankXToken address:     0x4d369BD339709021267E92702A9E4cE30be85706
// XSDToken address:       0x39400E67820c88A9D67F4F9c1fbf86f3D688e9F6
// BankXPool address:      0xfa0870077A65dBFde9052ad16B04C3e1A885CE2d
// XSDPool address:        0x8A4e0e2A778dF8cE4EA5D5108FFfE690CC9Ae07a
// CollateralPool address: 0x55A75059065296a5a8cdfadCF6945ed2cC5B8eC6
// PID controller address: 0x6683FFdA5267e1F7E9Bf6790849a4eCdD63ed134
// Reward Manager:         0x2147F5c02c2869E8C2d8F86471d3d7500355d698
// Router address:         0xEf8ef7e50Dc49AAe7beEb3D0004CB196F5a850C5
// Arbitrage:              0x79471BA0cCA010D8623f896C593d546691E53e5e
// CD address:             0xED024d771261D765B9Dc7b0947ef271ea006248F
// BankXNFT address:       0x11214D41a85306f725cdd9A318993b122bAa6DFe
// NFTBonus address:       0x369B83fe557Ef815572A340F5275D8D764615D15

// 12/14/2023 0856PST
// BankXToken address:     0x4d369BD339709021267E92702A9E4cE30be85706
// XSDToken address:       0x39400E67820c88A9D67F4F9c1fbf86f3D688e9F6
// BankXPool address:      0xfa0870077A65dBFde9052ad16B04C3e1A885CE2d
// XSDPool address:        0x8A4e0e2A778dF8cE4EA5D5108FFfE690CC9Ae07a
// CollateralPool address: 0x55A75059065296a5a8cdfadCF6945ed2cC5B8eC6
// PID controller address: 0x6683FFdA5267e1F7E9Bf6790849a4eCdD63ed134
// Reward Manager:         0xc8d2cdE3fc9690Dffa365a2c7e5c6bc8961893b8
// Router address:         0xEf8ef7e50Dc49AAe7beEb3D0004CB196F5a850C5
// Arbitrage:              0x79471BA0cCA010D8623f896C593d546691E53e5e
// CD address:             0xED024d771261D765B9Dc7b0947ef271ea006248F
// BankXNFT address:       0x11214D41a85306f725cdd9A318993b122bAa6DFe
// NFTBonus address:       0x369B83fe557Ef815572A340F5275D8D764615D15


// 11/20/2023 1306PST
// BankXToken address:     0x4d369BD339709021267E92702A9E4cE30be85706
// XSDToken address:       0x39400E67820c88A9D67F4F9c1fbf86f3D688e9F6
// BankXPool address:      0x992e69d0AD02976C7f85D0c8C5fa0757cE24D943
// XSDPool address:        0xaF3D6EF0ab609b8e213393EA80f0dFd518006d8e
// CollateralPool address: 0xc393773a2Dc94cCA27EF97f23e7CB156e2aF630c
// PID controller address: 0x5e2ca861A0714D262C5F9fd5791FB33401AACc23
// Reward Manager:         0xDc323E4C0F7dDdc348392A53097C093e272CC947
// Router address:         0xeef6DFa9091aDa7a5C0A2a3cd82Edf7845fA03b7
// Arbitrage:              0x86a74fB05715E973ee81fC0E631df3601f72414B
// CD address:             0xED024d771261D765B9Dc7b0947ef271ea006248F
// BankXNFT address:       0x11214D41a85306f725cdd9A318993b122bAa6DFe
// NFTBonus address:       0x369B83fe557Ef815572A340F5275D8D764615D15

// 06/28/2023 0759PDT
// BankXToken address:     0x4d369BD339709021267E92702A9E4cE30be85706
// XSDToken address:       0x39400E67820c88A9D67F4F9c1fbf86f3D688e9F6
// BankXPool address:      0x224E13D9eAB11eDc09411ef4bF800791a7EF6135
// XSDPool address:        0xbfBcB8BDE20cc6886877DD551b337833F3e0d96d
// CollateralPool address: 0x2946Ca638EB11830E83Feaa130aA8B13d37C3772
// PID controller address: 0x82a6405B9C38Eb1d012c7B06642dcb3D7792981B
// Reward Manager:         0x4823DFC9e029164A88A144626F3EC3736217a054
// RouterAddress:          0xfADDa925e10d07430f5d7461689fd90d3D81bB48
// Arbitrage:              0x1bd6bA575020Ff631488Dd43ab964dcc3018E4c3
// CD address:             0xED024d771261D765B9Dc7b0947ef271ea006248F
// BankXNFT address:       0x11214D41a85306f725cdd9A318993b122bAa6DFe
// NFTBonus address:       0x369B83fe557Ef815572A340F5275D8D764615D15

// 06/25/2023 1057PDT
// BankXToken address:     0x4d369BD339709021267E92702A9E4cE30be85706
// XSDToken address:       0x39400E67820c88A9D67F4F9c1fbf86f3D688e9F6
// BankXPool address:      0x224E13D9eAB11eDc09411ef4bF800791a7EF6135
// XSDPool address:        0xbfBcB8BDE20cc6886877DD551b337833F3e0d96d
// CollateralPool address: 0xD6cd86a72B03a4C4996923E74FC22EEe7713F908
// PID controller address: 0x82a6405B9C38Eb1d012c7B06642dcb3D7792981B
// Reward Manager:         0x4823DFC9e029164A88A144626F3EC3736217a054
// RouterAddress           0xfADDa925e10d07430f5d7461689fd90d3D81bB48
// Arbitrage:              0x1bd6bA575020Ff631488Dd43ab964dcc3018E4c3
// CD address:             0xED024d771261D765B9Dc7b0947ef271ea006248F
// BankXNFT address:       0x11214D41a85306f725cdd9A318993b122bAa6DFe
// NFTBonus address:       0x369B83fe557Ef815572A340F5275D8D764615D15

// 06/24/2023 0753PDT
// BankXToken address:     0x4d369BD339709021267E92702A9E4cE30be85706
// XSDToken address:       0x39400E67820c88A9D67F4F9c1fbf86f3D688e9F6
// BankXPool address:      0x224E13D9eAB11eDc09411ef4bF800791a7EF6135
// XSDPool address:        0xbfBcB8BDE20cc6886877DD551b337833F3e0d96d
// CollateralPool address: 0xD6cd86a72B03a4C4996923E74FC22EEe7713F908
// PID controller address: 0xfd248282d19F20A29fF27dF7D2663636275cb839
// Reward Manager:         0x4823DFC9e029164A88A144626F3EC3736217a054
// RouterAddress           0xfADDa925e10d07430f5d7461689fd90d3D81bB48
// Arbitrage:              0x1bd6bA575020Ff631488Dd43ab964dcc3018E4c3
// CD address:             0xED024d771261D765B9Dc7b0947ef271ea006248F
// BankXNFT address:       0x11214D41a85306f725cdd9A318993b122bAa6DFe
// NFTBonus address:       0x369B83fe557Ef815572A340F5275D8D764615D15

// 05/31/2023 0849PDT
// BankXToken address:     0x4d369BD339709021267E92702A9E4cE30be85706
// XSDToken address:       0x39400E67820c88A9D67F4F9c1fbf86f3D688e9F6
// BankXPool address:      0x224E13D9eAB11eDc09411ef4bF800791a7EF6135
// XSDPool address:        0xbfBcB8BDE20cc6886877DD551b337833F3e0d96d
// CollateralPool address: 0xD6cd86a72B03a4C4996923E74FC22EEe7713F908
// PID controller address: 0xfd248282d19F20A29fF27dF7D2663636275cb839
// Reward Manager:         0x4823DFC9e029164A88A144626F3EC3736217a054
// RouterAddress           0x83819750E19EC166C223e0EB3C0Aa3618a3cD76D
// Arbitrage:              0x1bd6bA575020Ff631488Dd43ab964dcc3018E4c3
// CD address:             0xED024d771261D765B9Dc7b0947ef271ea006248F
// BankXNFT address:       0x11214D41a85306f725cdd9A318993b122bAa6DFe
// NFTBonus address:       0x369B83fe557Ef815572A340F5275D8D764615D15

// 05/19/2023 1041PDT
// BankXToken address:     0x4d369BD339709021267E92702A9E4cE30be85706
// XSDToken address:       0x39400E67820c88A9D67F4F9c1fbf86f3D688e9F6
// BankXPool address:      0x224E13D9eAB11eDc09411ef4bF800791a7EF6135
// XSDPool address:        0xbfBcB8BDE20cc6886877DD551b337833F3e0d96d
// CollateralPool address: 0xD6cd86a72B03a4C4996923E74FC22EEe7713F908
// PID controller address: 0xfd248282d19F20A29fF27dF7D2663636275cb839
// Reward Manager:         0x4823DFC9e029164A88A144626F3EC3736217a054
// Router address:         0x33cFEa0d1c0CAbEDb7e406618EC89F1fbA793E99
// Arbitrage:              0x1bd6bA575020Ff631488Dd43ab964dcc3018E4c3
// CD address:             0xED024d771261D765B9Dc7b0947ef271ea006248F
// BankXNFT address:       0x11214D41a85306f725cdd9A318993b122bAa6DFe
// NFTBonus address:       0x369B83fe557Ef815572A340F5275D8D764615D15

// 05/09/2023 1348PDT
// BankXToken address:     0x4d369BD339709021267E92702A9E4cE30be85706
// XSDToken address:       0x39400E67820c88A9D67F4F9c1fbf86f3D688e9F6
// BankXPool address:      0x224E13D9eAB11eDc09411ef4bF800791a7EF6135
// XSDPool address:        0xbfBcB8BDE20cc6886877DD551b337833F3e0d96d
// CollateralPool address: 0x539Ab17ddfd5a468F033521126C3e7cE0213567F
// PID controller address: 0xfd248282d19F20A29fF27dF7D2663636275cb839
// Reward Manager:         0x4823DFC9e029164A88A144626F3EC3736217a054
// Router address:         0x33cFEa0d1c0CAbEDb7e406618EC89F1fbA793E99
// Arbitrage:              0x1bd6bA575020Ff631488Dd43ab964dcc3018E4c3
// CD address:             0xED024d771261D765B9Dc7b0947ef271ea006248F
// BankXNFT address:       0x11214D41a85306f725cdd9A318993b122bAa6DFe
// NFTBonus address:       0x369B83fe557Ef815572A340F5275D8D764615D15

//03/26/2023 0120PDT
// BankXToken address      0x9c47B70ed6349B492CCb1e600Db00e0bC0D32707
// XSDToken address        0xC1D30D3e0BdE9232b4936B70B152DA899354B453
// BankXPool address       0x73cD72ea1d09de5e85F9D5701b3AEE012A68C933
// XSDPool address         0xCcBa9CE2cc4b2e8e78503b4Aa5582EEAEc95354D
// CollateralPool address  0x1102A35e0A68Dc30d0E340d6d7E5B7DD9f01cd9c
// PID controller address  0x00E858770ab0d344a977F44cD427b3E0d35699B0
// Reward Manager          0xc8eA61c585B17f307c4C1f1C629e123E60Be2e9E
// Router address          0xA421782b5F28cb87140fcd1E4Ed829BcA495d067
// Arbitrage:              0x9C000477fe38949B00A839CbD9c917A1Fe75b688
// CD Address              0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// BankXNFT address        0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus address        0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC

// 03/22/2023 1127PDT
// BankXToken address      0x9c47B70ed6349B492CCb1e600Db00e0bC0D32707
// XSDToken address        0xC1D30D3e0BdE9232b4936B70B152DA899354B453
// BankXPool address       0x73cD72ea1d09de5e85F9D5701b3AEE012A68C933
// XSDPool address         0xCcBa9CE2cc4b2e8e78503b4Aa5582EEAEc95354D
// CollateralPool address  0x1102A35e0A68Dc30d0E340d6d7E5B7DD9f01cd9c
// PID controller address  0x00E858770ab0d344a977F44cD427b3E0d35699B0
// Reward Manager          0xc8eA61c585B17f307c4C1f1C629e123E60Be2e9E
// Router address          0xA421782b5F28cb87140fcd1E4Ed829BcA495d067
// Arbitrage:              0x9C000477fe38949B00A839CbD9c917A1Fe75b688
// CD Address              0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// BankXNFT address        0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus address        0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC
