import './styles.scss';

import { Loading } from 'assets/Loading';
import axios from 'axios';
import cx from 'classnames';
import { DashboardLayout } from 'components/layouts/DashboardLayout';
import PricingChart from 'components/PricingChart';
import { useAppSettings } from 'context/AppSettingsProvider';
import {
    format, startOfYear, subDays, subMonths, subYears
} from 'date-fns';
import { Logger } from 'helpers/logging';
import { useWallet } from 'hooks/useWallet';
import React, {
    useEffect, useMemo, useState
} from 'react';

type TPriceDataPoint = {
    timestamp: string
    token_price: number
    silver_price: number
    market_cap: number
    collateral_ratio: number
    token: 'XSD' | 'BankX'
    network: 'ETH' | 'BSC'
    tvl: number
}

type TPriceResponse = {
    priceData: TPriceDataPoint[]
    meta: {
        offset: number
        total: string
        next: null | number
    }
}

export type ETimeRanges = '1d' | '7d' | '1m' | '3m' | '1y' | 'all'

type TChartDataPoint = { date: string; value: number }

function formatDateTime(date: Date) {
    return format(date, 'yyyy-MM-dd HH:mm:ss');
}

function tokenToChainCode(token: string) {
    switch (token) {
        case 'BNB':
            return 'bsc';
        case 'ETH':
            return 'eth';
        // Polygon
        case 'MATIC':
            return 'polygon';
        // Arbitrum One
        case 'aETH':
            return 'arbitrum';
        // Avalance
        case 'AVAX':
            return 'avalanche';
        // Fantom Opera
        case 'FTM':
            return 'ftm';
        // optimism
        case 'oETH':
            return 'optimism';
        default:
            return token.toLowerCase();
    }
}

function getDateTimeInterval(interval?: ETimeRanges) {
    const currentDate = new Date();
    const now = new Date(
        Date.UTC(
            currentDate.getUTCFullYear(),
            currentDate.getUTCMonth(),
            currentDate.getUTCDate(),
            currentDate.getUTCHours(),
            currentDate.getUTCMinutes(),
            currentDate.getUTCSeconds()
        )
    );
    let date;

    switch (interval) {
        case '1d':
            date = subDays(now, 1);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);
            break;
        case '7d':
            date = subDays(now, 7);
            break;
        case '1m':
            date = subMonths(now, 1);
            break;
        case '3m':
            date = subMonths(now, 3);
            break;
        case '1y':
            date = subYears(now, 1);
            break;
        case 'all':
            date = startOfYear(new Date('2020-01-01'));
            break;
        default:
            date = now;
    }

    return formatDateTime(date);
}

function getEndpointUrl(
    network: string,
    startDate: string,
    token: 'XSD' | 'BankX',
    offset = 0
) {
    return `https://sacrifice-app.herokuapp.com/bankx/price?network=${network}&ts_from=${startDate}&token=${token}&offset=${offset}`;
}

export default function PricingPage() {
    const { getThemedClass: tcx } = useAppSettings();
    const { tokenLabel } = useWallet();
    const [data, setData] = useState<TPriceDataPoint[] | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedAsset, setSelectedAsset] = useState<'XSD' | 'BankX'>('XSD');
    const [selectedChartType, setSelectedChartType] = useState<
    'price' | 'marketCap'
    >('price');
    const [selectedTimeRange, setSelectedTimeRange] = useState<{
        label: ETimeRanges
        from: string
        to: string
    }>({
        label: 'all',
        from: getDateTimeInterval('all'),
        to: getDateTimeInterval(),
    });

    useEffect(() => {
        const endpoint = getEndpointUrl(
            tokenToChainCode(tokenLabel),
            encodeURI(selectedTimeRange.from),
            selectedAsset
        );

        async function fetchData(url: string) {
            setIsLoading(true);
            let allData: TPriceDataPoint[] = [];

            async function fetchPaginatedData(url: string): Promise<void> {
                const response = await axios.get<TPriceResponse>(url);
                const data = await response.data;
                allData = [...allData, ...(data.priceData || [])];

                if (data.meta.next !== null) {
                    const nextEndpoint = getEndpointUrl(
                        tokenToChainCode(tokenLabel),
                        encodeURI(selectedTimeRange.from),
                        selectedAsset,
                        data.meta.next
                    );
                    return await fetchPaginatedData(nextEndpoint);
                }
            }

            await fetchPaginatedData(url);
            setData(allData);
            setIsLoading(false);
        }

        fetchData(endpoint);
    }, [
        selectedTimeRange.from,
        selectedAsset,
        tokenLabel
    ]);

    const sanitizedData = useMemo(() => {
        return data?.reduce((accumulator, current) => {
            // filter out token data that doesn't match the selected asset
            if (selectedAsset !== current.token) {
                return accumulator;
            }

            if (selectedChartType === 'price') {
                return [...accumulator,
                    {
                        date: current.timestamp,
                        value: current.token_price,
                    }];
            }

            if (selectedChartType === 'marketCap') {
                return [...accumulator,
                    {
                        date: current.timestamp,
                        value: current.market_cap,
                    }];
            }

            return accumulator;
        }, [] as TChartDataPoint[]);
    }, [
        data,
        selectedAsset,
        selectedChartType
    ]);

    let silverPriceData: TChartDataPoint[] = [];

    if (data && selectedAsset === 'XSD' && selectedChartType === 'price') {
        silverPriceData = data?.reduce((accumulator, current) => {
            return [...accumulator,
                {
                    date: current.timestamp,
                    value: current.silver_price,
                }];
        }, [] as TChartDataPoint[]);
    }

    Logger.log('PricingPage:', {
        selectedAsset,
        selectedChartType,
        selectedTimeRange,
        sanitizedData,
        silverPriceData,
    });

    return (
        <DashboardLayout>
            <div className={tcx('content-wrapper')}>
                <div className={cx(tcx('main-card'), 'single-card', 'pricing-card')}>
                    <div className='pricing-buttons'>
                        <div className='token-buttons'>
                            <button
                                className={cx(
                                    'pricing-button',
                                    'button-tab',
                                    'button-tab-sm',
                                    'remove-border-radius-right',
                                    selectedAsset === 'BankX' && 'active'
                                )}
                                style={{
                                    marginRight: 0,
                                }}
                                onClick={() => setSelectedAsset('BankX')}
                            >
                BankX
                            </button>
                            <button
                                className={cx(
                                    'pricing-button',
                                    'button-tab',
                                    'button-tab-sm',
                                    'remove-border-radius-left',
                                    selectedAsset === 'XSD' && 'active'
                                )}
                                onClick={() => setSelectedAsset('XSD')}
                            >
                XSD
                            </button>
                        </div>
                        <div className='chart-type-buttons'>
                            <button
                                className={cx(
                                    'pricing-button',
                                    'button-tab',
                                    'button-tab-sm',
                                    'remove-border-radius-right',
                                    selectedChartType === 'price' && 'active-alt'
                                )}
                                style={{
                                    marginRight: 0,
                                }}
                                onClick={() => setSelectedChartType('price')}
                            >
                PRICE
                            </button>
                            <button
                                className={cx(
                                    'pricing-button',
                                    'button-tab',
                                    'button-tab-sm',
                                    'remove-border-radius-left',
                                    selectedChartType === 'marketCap' && 'active-alt'
                                )}
                                onClick={() => setSelectedChartType('marketCap')}
                            >
                MKT CAP
                            </button>
                        </div>
                        <div className='timespan-buttons'>
                            <button
                                className={cx(
                                    'pricing-button',
                                    'button-tab',
                                    'button-tab-sm',
                                    'minimized',
                                    'remove-border-radius-right',
                                    selectedTimeRange.label === '1d' && 'active-alt'
                                )}
                                onClick={() =>
                                    setSelectedTimeRange({
                                        label: '1d',
                                        from: getDateTimeInterval('1d'),
                                        to: getDateTimeInterval(),
                                    })
                                }
                            >
                1D
                            </button>
                            <button
                                className={cx(
                                    'pricing-button',
                                    'button-tab',
                                    'button-tab-sm',
                                    'minimized',
                                    'remove-border-radius-left',
                                    'remove-border-radius-right',
                                    selectedTimeRange.label === '7d' && 'active-alt'
                                )}
                                onClick={() =>
                                    setSelectedTimeRange({
                                        label: '7d',
                                        from: getDateTimeInterval('7d'),
                                        to: getDateTimeInterval(),
                                    })
                                }
                            >
                7D
                            </button>
                            <button
                                className={cx(
                                    'pricing-button',
                                    'button-tab',
                                    'button-tab-sm',
                                    'minimized',
                                    'remove-border-radius-left',
                                    'remove-border-radius-right',
                                    selectedTimeRange.label === '1m' && 'active-alt'
                                )}
                                onClick={() =>
                                    setSelectedTimeRange({
                                        label: '1m',
                                        from: getDateTimeInterval('1m'),
                                        to: getDateTimeInterval(),
                                    })
                                }
                            >
                1M
                            </button>
                            <button
                                className={cx(
                                    'pricing-button',
                                    'button-tab',
                                    'button-tab-sm',
                                    'minimized',
                                    'remove-border-radius-left',
                                    'remove-border-radius-right',
                                    selectedTimeRange.label === '3m' && 'active-alt'
                                )}
                                onClick={() =>
                                    setSelectedTimeRange({
                                        label: '3m',
                                        from: getDateTimeInterval('3m'),
                                        to: getDateTimeInterval(),
                                    })
                                }
                            >
                3M
                            </button>
                            <button
                                className={cx(
                                    'pricing-button',
                                    'button-tab',
                                    'button-tab-sm',
                                    'minimized',
                                    'remove-border-radius-left',
                                    'remove-border-radius-right',
                                    selectedTimeRange.label === '1y' && 'active-alt'
                                )}
                                onClick={() =>
                                    setSelectedTimeRange({
                                        label: '1y',
                                        from: getDateTimeInterval('1y'),
                                        to: getDateTimeInterval(),
                                    })
                                }
                            >
                1Y
                            </button>
                            <button
                                className={cx(
                                    'pricing-button',
                                    'button-tab',
                                    'button-tab-sm',
                                    'minimized',
                                    'remove-border-radius-left',
                                    selectedTimeRange.label === 'all' && 'active-alt'
                                )}
                                onClick={() =>
                                    setSelectedTimeRange({
                                        label: 'all',
                                        from: getDateTimeInterval('all'),
                                        to: getDateTimeInterval(),
                                    })
                                }
                            >
                All
                            </button>
                        </div>
                    </div>
                    {data && !isLoading ? (
                        <PricingChart data={sanitizedData} width={500} silverPriceData={silverPriceData} />
                    ) : (
                        <div className='pricing-loading-container'>
                            <Loading size='100px' color='#1882c4' />
                        </div>
                    )}
                </div>
            </div>
        </DashboardLayout>
    );
}
