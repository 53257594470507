import { AddressMap } from './types';

export const OptimismMainnetAddresses: AddressMap = {
    BankXToken:           '0x93Abe713002526D4cE708f890139f19313012f95',
    XSDToken:             '0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683',
    BankXPool:            '0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84',
    XSDPool:              '0xaB1c27a3B78d9afCDe9963780af4Ff48D6b816A2',
    CollateralPool:       '0xA6013450c9A50faF4F76B964701941618537123a',
    PIDController:        '0x5997a08fD19bB3E397b25d41c1c10D2F2E0299F7',
    RewardManager:        '0x4f9c91292E6dE192de3a42028f8ce48689c62a61',
    RouterAddress:        '0xb17832d20dc343650949867f8622CC4f6772AfaC',
    Arbitrage:            '0xE273a6ca1BAc1a86568647BB67aAb4d23bb3425C',
    CertificateOfDeposit: '0xc79461C653bCd0137D062ec87B9b821f7c9bCc26',
    BankXNFT:             '0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C',
    NFTBonus:             '0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC',
};

// 10/04/2024 1524PDT
// BankX Token Address:    0x93Abe713002526D4cE708f890139f19313012f95
// XSD Token Address:      0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// BankX Pool Address:     0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84
// XSD Pool Address:       0xaB1c27a3B78d9afCDe9963780af4Ff48D6b816A2
// CollateralPool:         0xA6013450c9A50faF4F76B964701941618537123a
// PID controller address: 0x5997a08fD19bB3E397b25d41c1c10D2F2E0299F7
// Reward Manager:         0x4f9c91292E6dE192de3a42028f8ce48689c62a61
// Router:                 0xb17832d20dc343650949867f8622CC4f6772AfaC
// Arbitrage:              0xE273a6ca1BAc1a86568647BB67aAb4d23bb3425C
// CD:                     0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// NFT:                    0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus:               0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC

// 8/26/2024 1944 PDT
// BankX Token Address: 0x93Abe713002526D4cE708f890139f19313012f95
// XSD Token Address:   0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// BankX Pool Address:  0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84
// XSD Pool Address:    0xaB1c27a3B78d9afCDe9963780af4Ff48D6b816A2
// CollateralPool:      0xA6013450c9A50faF4F76B964701941618537123a
// PID:                 0xa386b0B77e79BE61DEFfD56ACa9De734712E0701
// Reward Manager:      0x4f9c91292E6dE192de3a42028f8ce48689c62a61
// Router:              0xb17832d20dc343650949867f8622CC4f6772AfaC
// Arbitrage:           0xE273a6ca1BAc1a86568647BB67aAb4d23bb3425C
// CD:                  0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// NFT:                 0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus:            0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC

// 8/26/2024 1944 PDT
// BankX Token Address: 0x93Abe713002526D4cE708f890139f19313012f95
// XSD Token Address:   0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// BankX Pool Address:  0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84
// XSD Pool Address:    0xaB1c27a3B78d9afCDe9963780af4Ff48D6b816A2
// CollateralPool:      0x516f6b1680bC2b6a626128De1c2A8Cc3dd72C4eA
// PID:                 0xeCB3E1076F4d112d3A943B2d3Cef6803E7eF84C2
// Reward Manager:      0x4f9c91292E6dE192de3a42028f8ce48689c62a61
// Router:              0xb17832d20dc343650949867f8622CC4f6772AfaC
// Arbitrage:           0xE273a6ca1BAc1a86568647BB67aAb4d23bb3425C
// CD:                  0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// NFT:                 0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus:            0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC

// 02/27/2024 2027PST
// BankX Token Address: 0x93Abe713002526D4cE708f890139f19313012f95
// XSD Token Address:   0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// BankX Pool Address:  0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84
// XSD Pool Address:    0xaB1c27a3B78d9afCDe9963780af4Ff48D6b816A2
// CollateralPool:      0x45B07536b320297eBf525a87373A13dAf10b17f3
// PID:                 0x58421507d10A4c57a761E8AAd5382D5564A682F5
// Reward Manager:      0x0626A71D29f85c0fC665612623991Aa2EA2EAB62
// Router:              0x82B403e35D393174f33Ac8c72147D2BB3dC5Cc06
// Arbitrage:           0xE273a6ca1BAc1a86568647BB67aAb4d23bb3425C
// CD:                  0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// NFT:                 0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus:            0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC
