import { ethers } from 'ethers';
import { Logger } from 'helpers/logging';

import { toDecimalString } from './number';
import { getSilverPricePythResponse } from './pyth';

export async function getCollateralPercentage (xsdContract: ethers.Contract) {
    const collateral = await xsdContract.global_collateral_ratio(); // globalCollateralValue
    const formatted = toDecimalString(collateral, 6);

    Logger.log('getCollateralPercentage:', formatted, collateral);
    return formatted;
}

export async function getXSDPrice (pidContract: ethers.Contract) {
    const xsdPrice = await pidContract.xsd_price();
    const formatted = toDecimalString(xsdPrice, 6);

    Logger.log('xsdPrice:', formatted, xsdPrice);
    return formatted;
}

export async function getBankXTokenPrice (pidContract: ethers.Contract) {
    const bankXPrice = await pidContract.bankx_price();
    const formatted = toDecimalString(bankXPrice, 6);

    Logger.log('bankXPrice:', formatted, bankXPrice);
    return formatted;
}

async function getEthPrice(xsdTokenContract: ethers.Contract): Promise<string> {
    const ethPrice = await xsdTokenContract.eth_usd_price();
    const formatted = toDecimalString(ethPrice, 6);
    Logger.log('getEthPrice:', ethPrice, formatted);

    return formatted;
}

export async function getEthPriceInUsd (xsdTokenContract: ethers.Contract, chain: string | null): Promise<string> {
    if (!chain) {
        return '';
    }

    return getEthPrice(xsdTokenContract);
}

const SILVER_PRICE_PER_OUNCE = 31.1035;

export async function getSilverPricePerGram (xsdContract: ethers.Contract): Promise<number> {
    const silverPrice = await xsdContract.xag_usd_price();
    const formatted = toDecimalString(silverPrice, 6);

    Logger.log('silverPrice:', silverPrice, formatted);
    return Number(formatted) / SILVER_PRICE_PER_OUNCE;
}

export async function getSilverPriceFromPyth(): Promise<number> {
    const body = await getSilverPricePythResponse();
    const price = body?.parsed?.[0]?.price;
    const silver = Number(price?.price)  / Math.pow(10, Math.abs(price?.expo));

    return silver / SILVER_PRICE_PER_OUNCE;
}
