import cx from 'classnames';
import { ConnectButton } from 'components/ConnectButton/ConnectButton';
import { FormattedValue } from 'components/FormattedValue';
import { Label } from 'components/Generics';
import { DashboardLayout } from 'components/layouts/DashboardLayout';
import { MintInfo } from 'components/MintInfo';
import { Settings } from 'components/Settings';
import { TextInput, useTextInputContext } from 'components/TextInput';
import { TextInputName } from 'components/TextInput/types';
import TopCards from 'components/TopCards';
import { useAppSettings } from 'context/AppSettingsProvider';
import { useGlobalValues } from 'context/ContractValuesProvider';
import { useWeb3Context } from 'context/Web3Provider';
import { isValidInput } from 'helpers/input';
import { Logger } from 'helpers/logging';
import {
    calculateMintRequirements,
    getMaxXSDMintAmount,
    mintAlgorithmicXSD,
    mintFractionalXSD,
    mintOneToOne,
} from 'helpers/minting';
import { getValidInputValue } from 'helpers/number';
import { useCustomLoadingMessage } from 'hooks/useCustomLoadingMessages';
import { useWallet } from 'hooks/useWallet';
import { every, some } from 'lodash';
import React from 'react';

import Styles from './styles.module.scss';

export function Mint(): React.ReactElement {
    /* Settings
     -------------------------------------------- */
    const { calculateSlippageForOutput, getThemedClass: tcx } = useAppSettings();

    /* UI state
     -------------------------------------------- */
    const globalValues = useGlobalValues();
    const walletValues = globalValues.walletValues;

    const { useInputState } = useTextInputContext();
    const [xsdAmountToMint, setXsdAmountToMint] = useInputState(TextInputName.Mint);
    const {
        isLoading,
        message: customLoadingMessage,
        next: goToNextLoadingMessage,
        reset: resetLoadingMessages,
    } = useCustomLoadingMessage();

    /* wallet and contracts
     -------------------------------------------- */
    const { address: walletAddress, isConnected: isWalletConnected } = useWallet();
    const { getProvider, contracts } = useWeb3Context();
    const provider = getProvider();
    const {
        bankxTokenContract,
        collateralPoolContract,
        pidContract,
        proxyContract,
    } = contracts;

    /* calculations and formatting
     -------------------------------------------- */
    const {
        bankXTokenPrice,
        chain,
        collateralPercentage,
        ethPriceInUsd,
        mintInterestRate,
        silverPrice,
    } = globalValues;

    const interestRateOutput = String(Number(mintInterestRate) * 100);
    const {
        bankx: requiredBankX,
        eth: requiredEth,
    } = calculateMintRequirements({
        bankxTokenPrice: bankXTokenPrice,
        collateralPercentage,
        ethPriceInUsd,
        silverPrice,
        xsdToMint: xsdAmountToMint,
    });

    const canMint = every([
        isWalletConnected,
        isValidInput(xsdAmountToMint),
        Number(requiredEth) <= Number(walletValues.balance),
        Number(requiredBankX) <= Number(walletValues.bankx),
        !globalValues.isMintDisabled,
    ]);

    /* handlers
     -------------------------------------------- */
    async function handleMint() {
        if (!walletAddress) {
            return;
        }

        try {
            if (Number(collateralPercentage) === 1) {
                // 2 steps: mint -> wait
                resetLoadingMessages(2);
                await mintOneToOne(
                    requiredEth,
                    calculateSlippageForOutput(xsdAmountToMint),
                    collateralPoolContract,
                    provider,
                    goToNextLoadingMessage,
                );
            } else if (Number(collateralPercentage) === 0) {
                // 4 steps: price check -> approve -> mint -> wait
                resetLoadingMessages(4);
                await mintAlgorithmicXSD(
                    requiredBankX,
                    calculateSlippageForOutput(xsdAmountToMint),
                    collateralPoolContract,
                    bankxTokenContract,
                    provider,
                    pidContract,
                    goToNextLoadingMessage,
                    chain,
                    proxyContract,
                );
            } else {
                // 4 steps: price check -> approve -> mint -> wait
                resetLoadingMessages(4);
                await mintFractionalXSD(
                    requiredEth,
                    requiredBankX,
                    calculateSlippageForOutput(xsdAmountToMint),
                    collateralPoolContract,
                    bankxTokenContract,
                    pidContract,
                    provider,
                    goToNextLoadingMessage,
                    chain,
                    proxyContract,
                );
            }
        } catch (e) {
            Logger.error(e);
            throw e;
        }
    }

    function onXsdMintChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const value = e.target.value;
        setXsdAmountToMint(getValidInputValue(value));
    }

    const maxButtonConfig = {
        bankx: walletValues.bankx,
        bankxTokenPrice: bankXTokenPrice,
        collateralPercentage,
        eth: walletValues.balance,
        ethPriceInUsd,
        silverPrice,
    };

    const isMaxButtonDisabled = !every(maxButtonConfig);
    function handleMaxBtnClick() {
        setXsdAmountToMint(getMaxXSDMintAmount(maxButtonConfig));
    }

    const isBalanceExceeded = some([
        Number(requiredEth) > Number(walletValues.balance),
        Number(requiredBankX) > Number(walletValues.bankx),
        false,
    ]);

    return (
        <DashboardLayout>
            <div className={cx('content-wrapper', tcx('mint-page'))}>
                <TopCards />
                <div className={cx(tcx('main-card'), tcx('card-bg'), { 'disable-overlay': globalValues.isMintDisabled })}>
                    <div className="row">
                        <div className="col-md-6 curved-transition-container">
                            <div className={cx(tcx('white-card'), tcx('curved-transition'))}>
                                <Settings title="Mint XSD Stablecoin"/>
                                <div className="wc-body">
                                    <Label className='mb-2' text="If XSD is below the price of 1 gram of Silver, BUY it instead for a better price."/>
                                    <div className="form-group with-max-btn">
                                        <TextInput
                                            className={cx(tcx('form-control'), 'with-max-btn')}
                                            min="1"
                                            onChange={onXsdMintChange}
                                            name={TextInputName.Mint}
                                            placeholder="Amount of XSD to Mint"
                                            type="number"
                                            value={xsdAmountToMint}
                                            disabled={isLoading}
                                        />
                                        <button
                                            onClick={handleMaxBtnClick}
                                            className="button-1 max-btn"
                                            disabled={isMaxButtonDisabled}
                                        >
                                            MAX
                                        </button>
                                    </div>
                                    <div
                                        className={tcx('side-info-box2')}
                                        style={{ marginTop: '0' }}
                                    >
                                        <ul>
                                            <li>
                                                <label>{walletValues.tokenLabel} Available:</label>
                                                <FormattedValue value={walletValues.balance} isLoading={isLoading} precision={4}/>
                                            </li>
                                            <li>
                                                <label>BankX Available:</label>
                                                <FormattedValue value={walletValues.bankx} isLoading={isLoading} precision={4}/>
                                            </li>
                                            <li>
                                                <label>Minting Interest Rate:</label>
                                                <FormattedValue value={interestRateOutput} suffix="%"/>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <MintInfo {...globalValues.mintInfo} isLoading={isLoading}/>
                    </div>
                    <div
                        className={cx('row', tcx('row-status'))}
                        style={{ position: 'relative' }}
                    >
                        <div className="col-md-6">
                            <div className={cx(tcx('si-list'), 'bg-7', Styles.requires)}>
                                <ul className="mr-2 ul-fix">
                                    <li style={{ marginBottom: '5px' }}>
                                        <label>Requires</label>
                                    </li>
                                    <li>
                                        <label>{walletValues.tokenLabel}:</label>
                                        <FormattedValue value={requiredEth} precision={4}/>
                                    </li>
                                    <li>
                                        <label>BankX:</label>
                                        <FormattedValue value={requiredBankX} precision={4}/>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className={tcx('bu-wrap')}>
                                <b>NOTE:</b>
                                <p className={Styles.rewards}>
                                    To be paid the interest earned on the XSD you mint, you must
                                    redeem from the same wallet address as you minted. Otherwise,
                                    we have no way of knowing it is you.
                                </p>
                                <ConnectButton
                                    disabled={!canMint}
                                    className={tcx('button-1')}
                                    onClick={handleMint}
                                    onDone={resetLoadingMessages}
                                    customLoadingMessage={customLoadingMessage}
                                    isLimitExceeded={isBalanceExceeded}
                                >
                                    Mint
                                </ConnectButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
}
