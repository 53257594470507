import { ethers } from 'ethers';
import { mapValues } from 'lodash';
import numeral from 'numeral';

export function toHex(value: number | string): string {
    if (Number.isNaN(Number(value))) {
        return value as string;
    }

    const hexValue = value.toString(16);

    return `0x${hexValue}`;
}

export function toDecimalString(value: number | ethers.BigNumberish, decimals: number): string {
    return ethers.utils.formatUnits(value, decimals);
}

export function toBigNumber(value: string, decimals: number): ethers.BigNumber {
    const decimalsToUse = Math.max(decimals, 0);

    try {
        return ethers.utils.parseUnits(value, decimalsToUse);
    } catch {
        return ethers.utils.parseUnits('0', decimalsToUse);
    }
}

export function toBigInt(value: string, decimals: number): bigint {
    const bigNum = toBigNumber(value, decimals);
    return bigNum.toBigInt();
}

export function toBigIntString(value: number | string, decimals: number): string {
    const str = String(value);
    const bigInt = toBigInt(str, decimals);
    return bigInt.toString();
}

export function mapToBigNumber<T = Record<string, string>>(values: T, decimals: number): Record<keyof T, ethers.BigNumber> {
    return mapValues(values as unknown as object, (val: string) => toBigNumber(val, decimals)) as Record<keyof T, ethers.BigNumber>;
}

export function mapToNumber<T = Record<string, string>>(values: T): Record<keyof T, number> {
    return mapValues(values as unknown as object, Number) as Record<keyof T, number>;
}

interface FormatConfig {
    emDashOnZero?: boolean;
    precision?: number;
}

export function round(value: number): number {
    return Math.round((value + Number.EPSILON) * 100) / 100;
}

export function truncate(value: number, precision = 2): number {
    const multiplier = Math.pow(Math.max(0, 10), precision);

    return Math.trunc(value * multiplier) / multiplier;
}

export function addCommas(value: string, precision = 2): string {
    const padding = Array(precision).fill('0').join('');

    let format = '0,0';

    if (padding) {
        format += `.${padding}`;
    }

    return numeral(value).format(format);
}

export function toAmount(value: number | string, config: FormatConfig = {}): string {
    const precision = config?.precision ?? 0;
    const rounded = truncate(Number(value), precision);

    const result = addCommas(String(rounded || 0), precision);

    if (!Number(result) && Boolean(config.emDashOnZero)) {
        return '—';
    }

    return result;
}

export function toCurrency(amount: number | string, precision = 2): string {
    const truncated = truncate(Number(amount), precision);

    const mantissa = new Array(precision).fill('0').join('');
    return numeral(truncated).format(`$0,0.${mantissa}`);
}

interface ValidInputValueConfig {
    min?: number;
    max?: number;
}
export function getValidInputValue(input: string, config?: ValidInputValueConfig): string {
    let result = Number(input);

    if (Number.isNaN(result) || !input) {
        return input;
    }

    if (config?.min !== undefined) {
        result = Math.max(config.min, result);
    }

    if (config?.max !== undefined) {
        result = Math.min(config.max, result);
    }

    return String(result);
}

export function truncateDecimalValue(input: number | string, maxDecimalLength: number): string {
    const result = truncate(Number(input), maxDecimalLength);

    return String(result);
}

export function formatDecimalToPercentage(decimal: number): string {
    return numeral(decimal).format('0%');
}

export function abbreviated(input: number | string): string {
    return numeral(input).format('0.0a');
}
