import { saveEthToXsdSwap } from 'api/saveEthToXsdSwap';
import { ethers } from 'ethers';
import { Logger } from 'helpers/logging';
import { CustomLoadingMessage } from 'hooks/useCustomLoadingMessages';

import { TokenLabels } from './labels';
import {
    mapToBigNumber,
    toBigIntString,
    toDecimalString,
    truncateDecimalValue,
} from './number';
import { getDeadline } from './deadline';
import { systemCalculations } from './pool';
import { chainsCallbacksWithPriceUpdateData } from './pyth';

export function getTokenOptions(): string[] {
    return [
        TokenLabels.Erc,
        TokenLabels.Bankx,
        TokenLabels.Xsd,
    ];
}

export function getExchangeTokenOptions(selected: string): string[] {
    Logger.log('getExchangeTokenOptions: selected', selected);
    switch (selected) {
        case TokenLabels.Erc:
            return [TokenLabels.Xsd, TokenLabels.Bankx];
        case TokenLabels.Xsd:
        case TokenLabels.Bankx:
            return [TokenLabels.Erc];
        default:
            return [];
    }
}

interface ExchangePreset {
    source: string;
    target: string;
}
export function getExchangePresets(type?: 'buy' | 'sell' | string): ExchangePreset {
    switch (String(type).toLowerCase()) {
        case 'sell':
            return {
                source: TokenLabels.Xsd,
                target: TokenLabels.Erc,
            };
        case 'buy':
        default:
            return {
                source: TokenLabels.Erc,
                target: TokenLabels.Xsd,
            };
    }
}

function getSwapKey(source: string, target: string): string {
    return `${source}_${target}`;
}
interface ExchangeTokensConfig {
    sourceToken: string;
    targetToken: string;
    inputAmount: string;
    expectedOutput: string | null;
    xsdTokenContract: ethers.Contract;
    bankxTokenContract: ethers.Contract;
    routerContract: ethers.Contract;
    pidContract: ethers.Contract;
    proxyContract: ethers.Contract;
    provider: ethers.providers.Web3Provider;
    walletAddress: string | null;
    chain: string;
    shouldSendReferralCall: boolean;
    priceCheckBlockDelay: number;
    goToNextLoadingMessage(message?: string): void;
}
export async function exchangeTokens(
    {
        sourceToken,
        targetToken,
        inputAmount,
        expectedOutput: _expectedOutput,
        xsdTokenContract,
        bankxTokenContract,
        routerContract,
        pidContract,
        proxyContract,
        provider,
        walletAddress,
        chain,
        goToNextLoadingMessage,
        shouldSendReferralCall,
        priceCheckBlockDelay,
    }: ExchangeTokensConfig
): Promise<void> {
    const decimals = 18;
    const expectedOutput = truncateDecimalValue(_expectedOutput || '', decimals);

    if (!expectedOutput || !walletAddress) {
        return;
    }

    const input = toBigIntString(inputAmount, decimals);
    const output = toBigIntString(expectedOutput, decimals);
    const signer = provider.getSigner();
    const swapKey = getSwapKey(sourceToken, targetToken);

    Logger.log('exchangeTokens', sourceToken, '->', targetToken, inputAmount, input, expectedOutput, output);

    goToNextLoadingMessage(CustomLoadingMessage.SystemsCalculations);

    const deadline = await getDeadline(provider);
    const isRouterInteraction = true;
    await systemCalculations(pidContract, proxyContract, signer, chain, priceCheckBlockDelay);
    switch(swapKey) {
        /**
         * Eth to token
         */
        case getSwapKey(TokenLabels.Erc, TokenLabels.Bankx): {
            goToNextLoadingMessage();
            const routerReceipt = await chainsCallbacksWithPriceUpdateData(
                chain,
                proxyContract,
                (priceUpdateData) => routerContract.connect(signer).swapETHForBankX(output, deadline, priceUpdateData, { value: input }),
                () => routerContract.connect(signer).swapETHForBankX(output, deadline, { value: input }),
                isRouterInteraction,
            )

            Logger.log('exchangeTokens: routerReceipt', routerReceipt);
            goToNextLoadingMessage(CustomLoadingMessage.Swap);
            await routerReceipt.wait(1);
            break;
        }
        case getSwapKey(TokenLabels.Erc, TokenLabels.Xsd): {
            goToNextLoadingMessage();
            const routerReceipt = await chainsCallbacksWithPriceUpdateData(
                chain,
                proxyContract,
                (priceUpdateData) => routerContract.connect(signer).swapETHForXSD(output, deadline, priceUpdateData, { value: input }),
                () => routerContract.connect(signer).swapETHForXSD(output, deadline, { value: input }),
                isRouterInteraction,
            )

            if (shouldSendReferralCall) {
                saveEthToXsdSwap(routerReceipt.hash, chain);
            }
            Logger.log('exchangeTokens: routerReceipt', routerReceipt);
            goToNextLoadingMessage(CustomLoadingMessage.Swap);
            await routerReceipt.wait(1);
            break;
        }

        /**
         * Token to eth
         */
        case getSwapKey(TokenLabels.Bankx, TokenLabels.Erc): {
            Logger.log('exchangeTokens: routerAddress', routerContract.address);
            goToNextLoadingMessage();
            const bankxReceipt = await bankxTokenContract.connect(signer).approve(routerContract.address, input);
            Logger.log('exchangeTokens: bankxReceipt', bankxReceipt);
            await bankxReceipt.wait(1);

            goToNextLoadingMessage(CustomLoadingMessage.ConfirmingSwap);
            const routerReceipt = await chainsCallbacksWithPriceUpdateData(
                chain,
                proxyContract,
                (priceUpdateData) => routerContract.connect(signer).swapBankXForETH(output, input, deadline, priceUpdateData),
                () => routerContract.connect(signer).swapBankXForETH(output, input, deadline),
                isRouterInteraction,
            );

            Logger.log('exchangeTokens: routerReceipt', routerReceipt);
            goToNextLoadingMessage(CustomLoadingMessage.Swap);
            await routerReceipt.wait(1);

            break;
        }
        case getSwapKey(TokenLabels.Xsd, TokenLabels.Erc): {
            Logger.log('exchangeTokens: routerAddress', routerContract.address);
            goToNextLoadingMessage();
            const xsdReceipt = await xsdTokenContract.connect(signer).approve(routerContract.address, input);
            Logger.log('exchangeTokens: xsdReceipt', xsdReceipt);
            await xsdReceipt.wait(1);

            goToNextLoadingMessage(CustomLoadingMessage.ConfirmingSwap);
            const routerReceipt = await chainsCallbacksWithPriceUpdateData(
                chain,
                proxyContract,
                (priceUpdateData) => routerContract.connect(signer).swapXSDForETH(output, input, deadline, priceUpdateData),
                () => routerContract.connect(signer).swapXSDForETH(output, input, deadline),
                isRouterInteraction,
            );
            Logger.log('exchangeTokens: routerReceipt', routerReceipt);
            goToNextLoadingMessage(CustomLoadingMessage.Swap);
            await routerReceipt.wait(1);

            break;
        }
    }

    // final system calculations to update the price after swap
    goToNextLoadingMessage(CustomLoadingMessage.SystemsCalculations);
    await systemCalculations(pidContract, proxyContract, signer, chain);
}

interface ExchangeOutputConfig {
    sourceToken: string;
    targetToken: string;
    inputAmount: string;
    bankxPrice: string;
    xsdPrice: string;
    ethPrice: string;
    reverseOperation?: boolean; // used to calculate input using outputs
}
export function getExchangeOutput(
    config: ExchangeOutputConfig
): string {
    Logger.log('exchange ouput', config);
    const {
        sourceToken,
        targetToken,
        inputAmount,
        bankxPrice,
        xsdPrice,
        ethPrice,
        reverseOperation,
    } = config;

    if (!inputAmount || Number.isNaN(Number(inputAmount))) {
        return '';
    }

    const swapKey = getSwapKey(sourceToken, targetToken);
    const decimals = 18;
    const {
        inputAmount: inputAmountD18,
        bankxPrice: bankxD18,
        xsdPrice: xsdD18,
        ethPrice: ethD18,
    } = mapToBigNumber({
        inputAmount,
        bankxPrice,
        xsdPrice,
        ethPrice,
    }, decimals);
    const isBankXZero = Number(bankxPrice) === 0;
    const isXSDZero = Number(xsdPrice) === 0;

    if (reverseOperation) {
        switch (swapKey) {
            case getSwapKey(TokenLabels.Bankx, TokenLabels.Erc): {
                if (isBankXZero) {
                    return "0"
                }
                const bankx = inputAmountD18.mul(ethD18).div(bankxD18);
                return toDecimalString(bankx, decimals);
            }
            case getSwapKey(TokenLabels.Erc, TokenLabels.Bankx): {
                const eth = inputAmountD18.mul(bankxD18).div(ethD18);
                return toDecimalString(eth, decimals);
            }
            case getSwapKey(TokenLabels.Erc, TokenLabels.Xsd): {
                const eth = inputAmountD18.mul(xsdD18).div(ethD18);
                return toDecimalString(eth, decimals);
            }
            case getSwapKey(TokenLabels.Xsd, TokenLabels.Erc): {
                if (isXSDZero) {
                    return "0"
                }
                const xsd = inputAmountD18.mul(ethD18).div(xsdD18);
                return toDecimalString(xsd, decimals);
            }
            default:
                return '';
        }
    }

    switch (swapKey) {
        case getSwapKey(TokenLabels.Bankx, TokenLabels.Erc): {
            const eth = inputAmountD18.mul(bankxD18).div(ethD18);
            return toDecimalString(eth, decimals);
        }
        case getSwapKey(TokenLabels.Erc, TokenLabels.Bankx): {
            if (isBankXZero) {
                return "0"
            }
            const bankx = inputAmountD18.mul(ethD18).div(bankxD18);
            return toDecimalString(bankx, decimals);
        }
        case getSwapKey(TokenLabels.Erc, TokenLabels.Xsd): {
            if (isXSDZero) {
                return "0"
            }
            const xsd = inputAmountD18.mul(ethD18).div(xsdD18);
            return toDecimalString(xsd, decimals);
        }
        case getSwapKey(TokenLabels.Xsd, TokenLabels.Erc): {
            const eth = inputAmountD18.mul(xsdD18).div(ethD18);
            return toDecimalString(eth, decimals);
        }
        default:
            return '';
    }
}

/* https://github.com/Lance-Parker/BankX/blob/master/contracts/UniswapFork/Router.sol

    function swapETHForXSD(uint amountOut, address to)
        external

        payable
        override
    {
        (uint reserveA, uint reserveB, ) = IXSDWETHpool(XSDWETH_pool).getReserves();
        uint amounts = BankXLibrary.quote(amountOut, reserveA, reserveB);
        require(amounts >= amountOut, 'BankXRouter: INSUFFICIENT_OUTPUT_AMOUNT');
        IWETH(WETH).deposit{value: amounts}();
        assert(IWETH(WETH).transfer(XSDWETH_pool, amounts));
        IXSDWETHpool(XSDWETH_pool).swap(amountOut, 0, to);
    }

    function swapXSDForETH(uint amountOut, uint amountInMax, address to)
        external

        override
    {
        (uint reserveA, uint reserveB, ) = IXSDWETHpool(XSDWETH_pool).getReserves();
        uint amounts = BankXLibrary.quote(amountOut, reserveB, reserveA);
        require(amounts <= amountInMax, 'BankXRouter: EXCESSIVE_INPUT_AMOUNT');
        TransferHelper.safeTransferFrom(
            xsd_address, msg.sender, XSDWETH_pool, amounts
        );
        IXSDWETHpool(XSDWETH_pool).swap(amounts, 0, to);
        IWETH(WETH).withdraw(amounts);
        TransferHelper.safeTransferETH(to, amounts);
    }

    function swapETHForBankX(uint amountOut, address to)
        external

        override
        payable
    {
        (uint reserveA, uint reserveB, ) = IBankXWETHpool(BankXWETH_pool).getReserves();
        uint amounts = BankXLibrary.quote(amountOut, reserveA, reserveB);
        require(amounts >= amountOut, 'BankXRouter: INSUFFICIENT_OUTPUT_AMOUNT');
        IWETH(WETH).deposit{value: amounts}();
        assert(IWETH(WETH).transfer(BankXWETH_pool, amounts));
        IBankXWETHpool(BankXWETH_pool).swap(amountOut, 0, to);
    }

    function swapBankXForETH(uint amountOut, uint amountInMax, address to)
        external

        override
    {
        (uint reserveA, uint reserveB, ) = IBankXWETHpool(BankXWETH_pool).getReserves();
        uint amounts = BankXLibrary.quote(amountOut, reserveB, reserveA);
        require(amounts <= amountInMax, 'BankXRouter: EXCESSIVE_INPUT_AMOUNT');
        TransferHelper.safeTransferFrom(
            bankx_address, msg.sender, BankXWETH_pool, amounts
        );
        IBankXWETHpool(BankXWETH_pool).swap(amounts, 0, to);
        IWETH(WETH).withdraw(amounts);
        TransferHelper.safeTransferETH(to, amounts);
    }

    */
